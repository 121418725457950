import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  TablePagination,
  Typography,
} from "@mui/material";
import CustomerTable from "./CustomerTable";
import {
  getAllCustomers,
  updateProjectStatus,
} from "../services/finance.service";
import DropdownField from "../question-fields/dropdown";
import { getOptionsFromQue, TABLE_KEY_MAPPING } from "../utils/mapping";
import SeatchNoteField from "../question-fields/SeatchNoteField";

const CustomerListPage = () => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [searchQuery, setSearchQuery] = useState(null);
  const [filter1, setFilter1] = useState(null);
  const [filter2, setFilter2] = useState(null);
  const [options, setOptions] = useState([]);
  const [curOrder, setCurOrder] = useState("DESC");
  const [currentTab, setCurrentTab] = useState("incompleted");

  const handleChanges = async (value, customer) => {
    setCustomers((prev) => {
      let newCustomers = prev.map((c) => {
        if (c.id === customer?.id) {
          return { ...c, project_status: value };
        }
        return c;
      });

      newCustomers = newCustomers.filter((c) => {
        if (currentTab === "incompleted") {
          return c.project_status !== "Complete";
        }
        return c.project_status === "Complete";
      });

      return newCustomers;
    });

    // API to update project status
    const response = await updateProjectStatus(customer?.id, {
      project_status: value,
    });

    // handle the API response if needed
    if (response?.data?.status) {
    } else {
    }
  };

  const handleFilter1Change = (event) => {
    let status = null;
    if (event.target.value !== "All") {
      status = event.target.value;
      const opts = getOptionsFromQue(event.target.value);
      setOptions(opts);
    }
    setFilter1(status);
    setFilter2(null);
  };

  const handleFilter2Change = (event) => {
    const value = event.target.value;
    setFilter2(value);
  };

  const fetchAndSetCustomers = async (
    searchQuery,
    tableKeys,
    status,
    order
  ) => {
    setIsLoading(true);
    const response = await getAllCustomers(
      page,
      rowsPerPage,
      tableKeys,
      status,
      searchQuery,
      order,
      currentTab
    );
    if (response?.data?.status) {
      setCustomers(response?.data?.entity?.customers);
      setTotalCustomers(response?.data?.entity?.totalItems); // assuming API returns total count of customers
    }
    setIsLoading(false);
  };

  const handleSearchChange = (newVal) => {
    setSearchQuery(newVal);
  };

  const handleFilterClear = () => {
    setSearchQuery(null);
    setFilter1(null);
    setFilter2(null);

    if (searchQuery || (filter1 && filter2)) {
      fetchAndSetCustomers();
    }
  };

  useEffect(() => {
    fetchAndSetCustomers();
  }, [page, rowsPerPage, currentTab]);

  const filteredCustomers = customers;

  const handleOnSearch = () => {
    if (searchQuery || (filter1 && filter2)) {
      const tableKeys = TABLE_KEY_MAPPING[filter1];
      fetchAndSetCustomers(searchQuery, tableKeys, filter2);
    }
  };

  let Questions = [
    "All",
    "Confirmation Email Review",
    "Delivery Ticket?",
    "COI?",
    "Project Data",
    "Transition Confirmation",
    "SF CONFIRMATION",
    "Invoice",
    "Custome Notes",
    "Open Question",
    "Project Status",
  ];

  const handleCreatedAtSorting = () => {
    const orderToSet = curOrder === "DESC" ? "ASC" : "DESC";
    setCurOrder(orderToSet);
    fetchAndSetCustomers("", "", "", orderToSet);
  };

  const handleChangeTab = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <Box sx={{ margin: "12px" }}>
      <Box
        mb={2}
        sx={{
          width: "100%",
          background:
            "linear-gradient(to right, rgba(30, 46, 90, 0.9), rgba(30, 46, 90, 0))",
          padding: "5px 5px 2px 0px",
          borderRadius: "6px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "white",
            padding: "5px 5px 5px 12px",
            minWidth: "180px",
            // borderTopLeftRadius: "6px",
            // borderBottomLeftRadius: "6px",
            // background: "linear-gradient(to right, rgba(30, 46, 90, 0.9), rgba(30, 46, 90, 0))",
          }}
        >
          Customer List
        </Typography>
        <Container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            cursor: "pointer",
          }}
          maxWidth="2xl"
        >
          <Box
            sx={{
              p: 2,
              borderBottom:
                currentTab === "incompleted"
                  ? `2px solid #1876d2`
                  : "1px solid gray",
              fontSize: "14px",
            }}
            onClick={() => handleChangeTab("incompleted")}
          >
            Open
          </Box>
          <Box
            sx={{
              p: 2,
              borderBottom:
                currentTab === "completed"
                  ? `2px solid #1876d2`
                  : "1px solid gray",
              fontSize: "14px",
            }}
            onClick={() => handleChangeTab("completed")}
          >
            Complete
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          paddingBottom: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ width: "30vw" }}>
            <DropdownField
              question={"Questions"}
              value={filter1 || "All"}
              options={Questions}
              handleChangeDropdown={handleFilter1Change}
            />
          </Box>
          <Box sx={{ width: "10vw" }}>
            <DropdownField
              disabled={!filter1}
              question={"Status"}
              value={filter2}
              options={options}
              handleChangeDropdown={handleFilter2Change}
            />
          </Box>

          <Box sx={{ width: "350px", backgroundColor: "white" }}>
            <SeatchNoteField
              key="mainSearchBarKey"
              id="mainSearchBar"
              name="mainSearchBar"
              question="Search by name, property type, job type"
              value={searchQuery || ""}
              handleNoteBlur={handleSearchChange}
              event="onChange"
              type="text"
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button variant="contained" color="primary" onClick={handleOnSearch}>
            Search
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "gray",
              "&:hover": {
                backgroundColor: "#606060", // Change this to the desired hover color
              },
            }}
            onClick={handleFilterClear}
          >
            Clear All
          </Button>
        </Box>
      </Box>

      <CustomerTable
        customers={filteredCustomers}
        page={page}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        handleChanges={handleChanges}
        handleCreatedAtSorting={handleCreatedAtSorting}
        curOrder={curOrder}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={totalCustomers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Box>
  );
};

export default CustomerListPage;
