import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import StepperIndexPage from "../Components";
import WithSecurity from "./WithSecurity";
import Home from "../Components/Forms/Home";
import InitialInvoicePdf from "../Components/Forms/InitialInvoicePdf";
import LandingPage from "../Components/Pages/LandingPage";
import AddFurniturePage from "../Components/Forms/Furniture/MainFurniturePage";
import MainFurniturePage from "../Components/Forms/Furniture/MainFurniturePage";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={<Navigate to="/ek1ThPbdwtcAQMtg71pMeCl1XRdrs85H/home" />}
        />
        <Route
          exact
          path="/ek1ThPbdwtcAQMtg71pMeCl1XRdrs85H"
          element={
            <WithSecurity>
              <StepperIndexPage />
            </WithSecurity>
          }
        />
        <Route
          exact
          path="/ek1ThPbdwtcAQMtg71pMeCl1XRdrs85H/home"
          element={
            <WithSecurity>
              <LandingPage />
            </WithSecurity>
          }
        />
        <Route
          exact
          path="/ek1ThPbdwtcAQMtg71pMeCl1XRdrs85H/furniture"
          element={
            <WithSecurity>
              <MainFurniturePage />
            </WithSecurity>
          }
        />
        <Route
          exact
          path="/initialInvoice"
          element={
            // <WithSecurity>
            <InitialInvoicePdf />
            // </WithSecurity>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
