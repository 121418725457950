import * as HttpService from "./http.service";
import {
  ADD_OPS_RESULT_DATA_TO_ZOHO,
  DELETE_FURNITURE,
  DELETE_OPEN_QUESTION,
  DELETE_TOLLGATE,
  DELETE_TRANSITION,
  GET_CUSTOMER,
  GET_CUSTOMERS_LIST,
  GET_CUSTOMER_FURNITURE,
  GET_OPS_RESULTING_DATA_BY_ID,
  GET_USER_BY_ID,
  TEMP_API,
  UPDATE_CUSTOMER_FURNITURE,
  UPDATE_CUSTOM_NOTE,
  UPDATE_CUSTOM_NOTES,
  UPDATE_INVOICE,
  UPDATE_INVOICE_PRODUCTS,
  UPDATE_OPEN_QUESTION,
  UPDATE_PROJECT_BACKLOG_STATUS,
  UPDATE_PROJECT_STATUS,
  UPDATE_QUESTION,
  UPDATE_ROOM_NAME_IN_ZOHO,
  UPDATE_TOLLGATE,
  UPDATE_TRANSITION,
  UPLOAD_IMAGE
} from "./url.service";

export const getOpsResultingData = (id) => {
  return HttpService.getWithOutAuth(GET_OPS_RESULTING_DATA_BY_ID(id));
};

export const pushOpsResultDatatoZoho = (data, id) => {
  return HttpService.postWithOutAuth(ADD_OPS_RESULT_DATA_TO_ZOHO(id), data);
};

export const getUserById = (id) => {
  return HttpService.getWithOutAuth(GET_USER_BY_ID(id));
};
export const getCustomerById = (id) => {
  return HttpService.getWithAuth(GET_CUSTOMER(id));
};
export const getAllCustomers = (
  page,
  size,
  filter1,
  filter2,
  searchQuery,
  order,
  currentTab
) => {
  return HttpService.getWithAuth(
    GET_CUSTOMERS_LIST(
      page,
      size,
      filter1,
      filter2,
      searchQuery,
      order,
      currentTab
    )
  );
};

export const updateQuestion = (id, quesObj) => {
  return HttpService.putWithAuth(UPDATE_QUESTION(id), quesObj);
};
export const updateInvoiceProduct = (id, prodDetails) => {
  return HttpService.putWithAuth(UPDATE_INVOICE_PRODUCTS(id), prodDetails);
};
export const updateNotes = (id, note) => {
  return HttpService.putWithAuth(UPDATE_CUSTOM_NOTES(id), note);
};
export const updateInvoice = (customerId, invoiceObj) => {
  return HttpService.putWithAuth(UPDATE_INVOICE(customerId), invoiceObj);
};
export const updateProjectStatus = (customerId, projectStatus) => {
  return HttpService.patchWithAuth(
    UPDATE_PROJECT_STATUS(customerId),
    projectStatus
  );
};
export const updateCustomNote = (customerId, customNoteObj) => {
  return HttpService.putWithAuth(UPDATE_CUSTOM_NOTE(customerId), customNoteObj);
};
export const updateOpenQuestion = (customerId, customNoteObj) => {
  return HttpService.putWithAuth(
    UPDATE_OPEN_QUESTION(customerId),
    customNoteObj
  );
};
export const uploadImage = (img) => {
  return HttpService.postWithAuthImg(UPLOAD_IMAGE(), img);
};
export const deleteOpenQuesion = (customerId, questionId) => {
  return HttpService.deleteWithAuth(
    DELETE_OPEN_QUESTION(customerId, questionId)
  );
};
export const deleteTransition = (customerId, questionId) => {
  return HttpService.deleteWithAuth(DELETE_TRANSITION(customerId, questionId));
};

export const updateTransition = (customerId, transitionObj) => {
  return HttpService.putWithAuth(UPDATE_TRANSITION(customerId), transitionObj);
};

/** Project backlog status update function */
export const updateProjectBacklogStatusApi = (zc_po_id, backlogStatusBody) => {
  return HttpService.patchWithAuth(
    UPDATE_PROJECT_BACKLOG_STATUS(zc_po_id),
    backlogStatusBody
  );
};

export const getCustomerFurnitureById = (id) => {
  return HttpService.getWithAuth(GET_CUSTOMER_FURNITURE(id));
};

export const updateCustomerFurniture = (customerId, furnitureObj) => {
  return HttpService.putWithAuth(
    UPDATE_CUSTOMER_FURNITURE(customerId),
    furnitureObj
  );
};

export const deleteFurniture = (furnitureId) => {
  return HttpService.deleteWithAuth(DELETE_FURNITURE(furnitureId));
};
export const deleteTollGate = (furnitureId) => {
  return HttpService.deleteWithAuth(DELETE_TOLLGATE(furnitureId));
};

export const updateCustomerTollGate = (customerId, furnitureObj) => {
  return HttpService.putWithAuth(UPDATE_TOLLGATE(customerId), furnitureObj);
};
