import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { isProjectDataStatusconfirmed } from "../utils/mapping";

const projectDataExcludeOptions = [
  "Yes. Reviewed w/ Open Questions",
  "Yes. Not Yet Reviewed",
];

const CustomerName = ({ customer }) => {
  const customerStatusConfirmed = useMemo(() => {
    const confirmations = customer.confirmations;

    let email_confirmed = confirmations.email_confirmed === "Yes. Confirmed";

    let coi_confirmed =
      confirmations.coi_confirmed === "Yes. Complete" ||
      confirmations.coi_confirmed === "Not Needed";

    let delivery_ticket_confirmed = false;
    if (confirmations.delivery_ticket_confirmed === "Not Needed") {
      delivery_ticket_confirmed = true;
    } else if (
      confirmations.delivery_ticket_confirmed === "Yes. Complete" &&
      (confirmations.supplier_receipt_confirmed === "Yes. Complete" ||
        confirmations.supplier_receipt_confirmed === "Not Needed" ||
        confirmations.supplier_receipt_confirmed === null)
    ) {
      delivery_ticket_confirmed = true;
    }

    const bothMatterportandMagicplanNull =
      confirmations.matterport_confirmed === null &&
      confirmations.magicplan_confirmed === null;

    let project_data_confirmed =
      !bothMatterportandMagicplanNull &&
      isProjectDataStatusconfirmed(confirmations);

    // if (
    //   (confirmations.matterport_confirmed === "Yes. Reviewed. Good to go." ||
    //     confirmations.magicplan_confirmed === "Yes. Reviewed. Good to go.") &&
    //   (!projectDataExcludeOptions.includes(
    //     confirmations.matterport_confirmed
    //   ) ||
    //     confirmations.matterport_confirmed === null ||
    //     confirmations.matterport_confirmed === "No existing file") &&
    //   (!projectDataExcludeOptions.includes(confirmations.magicplan_confirmed) ||
    //     confirmations.magicplan_confirmed === null ||
    //     confirmations.magicplan_confirmed === "No existing file")
    // ) {
    //   project_data_confirmed = true;
    // }

    const transition_confirmed =
      confirmations.transition_confirmed ===
        "Invoice : Reviewed and Approved" ||
      confirmations.transition_confirmed === "Ops : Reviewed and Approved" ||
      confirmations.transition_confirmed === "Not Needed";

    const sf_confirmed = confirmations.sf_confirmed === "Reviewed and Approved";
    const invoice_confirmed =
      confirmations.invoice_confirmed === "Yes. Reviewed. Good to go.";
    const custom_notes_confirmed =
      confirmations.custom_notes_confirmed === "Yes. Reviewed. Good to go.";
    const open_questions_confirmed =
      confirmations.open_questions_confirmed === "DONE" ||
      confirmations.open_questions_confirmed === "Not Needed";

    const furniture_confirmed =
      confirmations.furniture_confirmed === "Yes. Complete";

    if (
      email_confirmed &&
      delivery_ticket_confirmed &&
      coi_confirmed &&
      project_data_confirmed &&
      transition_confirmed &&
      sf_confirmed &&
      invoice_confirmed &&
      custom_notes_confirmed &&
      open_questions_confirmed &&
      furniture_confirmed
    ) {
      return true;
    } else {
      return false;
    }
  }, [customer]);

  const styleToApply = useMemo(() => {
    if (customerStatusConfirmed) {
      return {
        backgroundColor: "#008000ad",
      };
    } else {
      return {
        backgroundColor: "red",
      };
    }
  }, [customerStatusConfirmed]);

  return (
    <Box sx={{ py: 1 }}>
      <div
        style={{
          ...styleToApply,
          color: "white",
          padding: "6px",
          borderRadius: "8px",
        }}
      >
        {customer.name}
      </div>
    </Box>
  );
};

export default CustomerName;
