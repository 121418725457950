export const QuestionFormField = [
  ["Home"],
  [
    [
      {
        question: "Confirmation Email Review",
        key: "status",
        type: "DROPDOWN",
        option: ["Yes. Confirmed", "Yes w/ Open Questions"]
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      }

      // {
      //     question: "Upload Pdf",
      //     key: "doc_urls",
      //     type: "PDF",
      // },
    ]
  ],
  [
    [
      {
        question: "Delivery Ticket?",
        key: "status",
        type: "DROPDOWN",
        option: [
          "Yes. Complete",
          "Yes. In Process",
          "Yes. Not Yet Started",
          "Not Needed"
        ]
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      },
      {
        question: "Upload Pdf",
        key: "doc_urls",
        type: "PDF"
      }
    ],
    [
      {
        question: "Supplier Receipt",
        key: "status",
        type: "DROPDOWN",
        option: [
          "Yes. Complete",
          "Yes. In Process",
          "Yes. Not Yet Started",
          "Not Needed"
        ]
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      },
      {
        question: "Upload Pdf",
        key: "doc_urls",
        type: "PDF"
      },
      {
        question: "Amount",
        key: "details.amount",
        type: "CHECKBOX"
      },
      {
        question: "Quantity",
        key: "details.qty",
        type: "CHECKBOX"
      },
      {
        question: "Product Description",
        key: "details.prod_desc",
        type: "CHECKBOX"
      }
    ]
  ],

  [
    [
      {
        question: "COI?",
        key: "status",
        type: "DROPDOWN",
        option: [
          "Yes. Complete",
          "Yes. In Process",
          "Yes. Not Yet Started",
          "Not Needed"
        ]
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      },
      {
        question: "Sample Certificate Text",
        key: "details.sampleCertiText",
        type: "TEXT",
        isMultiLine: true
      },
      {
        question: "Sample Certificate Upload",
        key: "details.sampleCertiDocs",
        type: "PDF",
        canUploadMixFiles: true
      },
      {
        question: "Final Certificate Upload",
        key: "doc_urls",
        type: "PDF",
        canUploadMixFiles: true
      }
    ],
    [
      {
        question: "Building Approval?",
        key: "status",
        type: "DROPDOWN",
        option: [
          "Yes. Complete",
          "Yes. In Process",
          "Yes. Not Yet Started",
          "Not Needed"
        ]
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      },
      {
        question: "Building Papper Work",
        key: "doc_urls",
        type: "PDF",
        canUploadMixFiles: true
      }
    ]
  ],
  [
    [
      {
        question: "Estimated Start Date",
        key: "status",
        type: "DATE"
      },
      {
        question: "Est Start Date Confirmed",
        key: "details.estStartDateConfirm",
        type: "CHECKBOX"
      },
      {
        question: "Date NOTES",
        key: "note",
        type: "TEXT",
        isMultiLine: true
      }
    ]
  ],

  [
    [
      {
        question: "MAGICPLAN",
        key: "status",
        type: "DROPDOWN",
        option: [
          "Yes. Reviewed. Good to go.",
          "Yes. Reviewed w/ Open Questions",
          "Yes. Not Yet Reviewed",
          "No existing file"
        ]
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      },

      {
        question: "Image",
        key: "images",
        type: "IMAGE"
      }
    ],
    [
      {
        question: "MATTERPORT",
        key: "status",
        type: "DROPDOWN",
        option: [
          "Yes. Reviewed. Good to go.",
          "Yes. Reviewed w/ Open Questions",
          "Yes. Not Yet Reviewed",
          "No existing file"
        ]
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      },

      {
        question: "Image",
        key: "images",
        type: "IMAGE"
      }
    ]
  ],

  [
    [
      {
        question: "Transition Confirmation Needed?",
        key: "status",
        type: "DROPDOWN",
        option: ["Yes Needed", "Not Needed"]
      }
    ]
  ],
  [
    [
      {
        question: "SF CONFIRMATION",
        key: "status",
        type: "DROPDOWN",
        option: [
          "Reviewed and Approved",
          "Reviewed w/ Open Questions",
          "Not Yet Reviewed"
        ]
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      }
    ],
    [
      {
        question: "Confirmation of spaces included in project",
        key: "status",
        type: "DROPDOWN",
        option: [
          "Reviewed and Approved",
          "Reviewed w/ Open Questions",
          "Not Yet Reviewed"
        ]
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      }
    ]
  ],
  [
    [
      {
        question: "Is there furniture in the space?",
        key: "status",
        type: "DROPDOWN",
        option: ["Yes", "No", "Not Yet Reviewed"]
      },
      {
        question: "Who is Moving Furniture?",
        key: "status",
        type: "DROPDOWN",
        option: ["Customer Moving", "FlooredAtHome Moving"]
      },

      {
        question: "Items Reviewed",
        key: "details.itemReviwed",
        type: "CHECKBOX"
      },
      {
        question: "Plan Reviewed",
        key: "details.PlanReviewed",
        type: "CHECKBOX"
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      }
    ]
  ],
  [
    [
      {
        question: "Invoice"
      }
    ]
  ],
  [
    [
      {
        question: "Custome Notes"
      }
    ]
  ],
  [
    [
      {
        question: "Open Questions Needed?",
        key: "status",
        type: "DROPDOWN",
        option: ["Yes Needed", "Not Needed"]
      }
    ]
  ],
  [
    [
      {
        question: "Operation Project Review"
      }
    ]
  ],
  [
    [
      {
        question: "JT Upload",
        key: "doc_urls",
        type: "PDF",
        canUploadMixFiles: false
      }
    ]
  ]
];
