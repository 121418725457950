import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import GetIconFile from "../../assets/GetIconFile";

const customDialogContentStyle = {
  height: "610px",
  width: "800px",
};

const customDotStyles = `
    .image-slider-popup .slick-dots {
      bottom: -22px;
    }
    .image-slider-popup .slick-dots li button:before {
      font-size: 16px !important;
    }
    .image-slider-popup .slick-slide {
        height: 590px;
    }
  `;

const ImageSlider = ({
  openDialog,
  handleCloseDialog,
  filteredImagesFromMixedFiles,
  removeImageHandler,
  currentFileIndex,
}) => {
  const settings = {
    initialSlide: currentFileIndex,
    arrows: false,
    infinite: false,
    dots: true,
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
      <DialogContent style={customDialogContentStyle}>
        <DialogContentText>
          <style>{customDotStyles}</style>
          <Slider className="image-slider-popup" {...settings}>
            {filteredImagesFromMixedFiles?.map((image, index) => (
              <div key={image + index} className="flex justify-center relative">
                <img
                  src={image}
                  alt={`Image ${index}`}
                  className="mx-auto rounded-xl"
                  style={{ width: "730px", height: "665px" }}
                />
                <div
                  className={`absolute rounded-full bg-white padding-2.5 top-0 right-0`}
                  onClick={(e) => removeImageHandler?.(e, image)}
                >
                  <GetIconFile
                    data={{ width: "24px", height: "24px" }}
                    iconName="remove-icon"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ImageSlider;
