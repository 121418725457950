import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";

const NoteTextField = styled(TextField)(({}) => ({
  "&.MuiTextField-root": {
    "& .MuiInputLabel-root": {
      color: "#202020",
      fontSize: "13px",
      top: "2px",
      marginTop: "3px",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset>legend": {
      fontSize: "10px",
    },
  },
}));

const SoloAutoComplete = styled(Autocomplete)(({}) => ({
  "& .MuiFilledInput-root": {
    background: "white",
    // border: "1px solid #cbcbcb",
    borderBottom: 0,
    height: 48,
    paddingTop: "4px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset>legend": {
      fontSize: "10px",
    },
  },
}));

export default function CustomSelect({
  question,
  value,
  options,
  handleChangeDropdown,
  disabled = false,
}) {
  const handleChange = (event, value) => {
    // Restrict to only one selected value
    if (value.length <= 1) {
      handleChangeDropdown(value);
      // setSelectedOption(value);
    }
  };

  //   const handleChange = (event, value) => {
  //     console.log(value, selectedOption);
  //     const valueToSet = value[value.length - 1];
  //     // Restrict to only one selected value

  //     setSelectedOption([valueToSet]);
  //   };

  return (
    <Stack spacing={3}>
      <SoloAutoComplete
        multiple
        freeSolo
        id="tags-filled"
        options={options}
        value={value}
        onChange={handleChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <NoteTextField
            {...params}
            variant="outlined"
            label={question}
            disabled={disabled}
          />
        )}
      />
    </Stack>
  );
}

// Top 10 films as rated by IMDb users. http://www.imdb.com/chart/top
const top10Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Dark Knight", year: 2008 },
  { title: "Inception", year: 2010 },
];
