import styled from "@emotion/styled";
import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  TextField,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
  DialogContentText,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import GetIconFile from "../../assets/GetIconFile";

const UploadTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input[type='file']": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "50px",
    opacity: 0,
    cursor: "pointer",
  },
  "& .file-preview": {
    display: "flex",
    flexWrap: "wrap",
    // gap: "4px"
  },
  "&.MuiTextField-root": {
    "& .MuiInputBase-input": {
      padding: "14px",
      fontSize: "13px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiInputLabel-root": {
      color: "#202020",
      fontSize: "13px",
      top: "2px",
      marginTop: "3px",
    },
    "& .MuiInputLabel-shrink": {
      marginTop: "0",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset>legend": {
      fontSize: "10px",
    },
  },
}));

const isPDF = (file) => {
  return file.match(/\.pdf$/i);
};

const ImageUploadField = ({
  label,
  handleImageChange,
  name,
  id,
  files,
  accept,
  type,
  imgUploading,
  pdfUploading,
  width = "85%",
  handleRemoveFiles,
  ...restFieldProps
}) => {
  const [preview, setPreview] = useState([]);
  const [openPdf, setOpenPdf] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const reverseArray = [...files]?.reverse();
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileToRemove, setFileToRemove] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const customDialogContentStyle = {
    height: "610px",
    width: "800px",
  };
  const customDotStyles = `
    .image-slider-popup .slick-dots {
      bottom: -22px;
    }
    .image-slider-popup .slick-dots li button:before {
      font-size: 16px !important;
    }
    .image-slider-popup .slick-slide {
        height: 590px;
    }
  `;
  const scrollBoxRef = useRef();

  useEffect(() => {
    const scrollBox = scrollBoxRef.current;

    const handleWheel = (event) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        scrollBox.scrollLeft += event.deltaY;
      }
    };

    scrollBox.addEventListener("wheel", handleWheel);

    return () => {
      scrollBox.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const filteredImagesFromMixedFiles = reverseArray.filter(
    (file) => !isPDF(file)
  );

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const updatedPreviews = selectedFiles.map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
      type: file.type,
    }));

    setPreview((prev) => [...prev, ...updatedPreviews]);
    handleImageChange(selectedFiles);
  };

  const handlePdfClick = (pdfUrl) => {
    setCurrentPdf(pdfUrl);
    setOpenPdf(true);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
    setCurrentPdf(null);
  };

  const handleFileClick = (fileUrl, index) => {
    setCurrentFileIndex(index);
    setOpenDialog(true);
  };

  const handleRemoveClick = (file) => {
    setFileToRemove(file);
    setOpenConfirmDialog(true);
  };

  const handleConfirmRemove = () => {
    // Implement the removal logic here
    // Example: removeImageHandler(fileToRemove);
    handleRemoveFiles(fileToRemove);
    setOpenConfirmDialog(false);
    setFileToRemove(null);
  };

  const handleCancelRemove = () => {
    setOpenConfirmDialog(false);
    setFileToRemove(null);
  };

  const StartAdornment = () => {
    if (type === "PDF") {
      return (
        <>
          {pdfUploading ? (
            <div className="w-[127px] h-[85px] rounded-md border-[1px] border-[#80808036] flex justify-center items-center">
              <CircularProgress size={20} />
            </div>
          ) : (
            <img src="/takePhotoIcon.svg" alt="Upload Icon" />
          )}
        </>
      );
    } else if (type === "IMAGE") {
      return (
        <>
          {imgUploading ? (
            <div className="w-[127px] h-[85px] rounded-md border-[1px] border-[#80808036] flex justify-center items-center">
              <CircularProgress size={20} />
            </div>
          ) : (
            <img src="/takePhotoIcon.svg" alt="Upload Icon" />
          )}
        </>
      );
    }
    return null;
  };

  const settings = {
    initialSlide: currentFileIndex,
    arrows: false,
    infinite: false,
    dots: true,
  };

  const removeImageHandler = useCallback(
    async (e, image) => {
      e.stopPropagation();
      // Implement the removal logic here
    },
    [reverseArray]
  );

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentFileIndex(0);
  };

  return (
    <>
      <Box position="relative">
        <UploadTextField
          name={name}
          id={id}
          variant="outlined"
          fullWidth
          onChange={handleFileChange}
          label={label}
          inputProps={{
            accept,
          }}
          sx={{
            marginTop: 0,
            width: "100%",
            ".MuiOutlinedInput-root": {
              height: "150px",
            },
            img: {
              paddingRight: "1rem",
            },
          }}
          InputProps={{
            startAdornment: <StartAdornment />,
            type: "file",
            id: "file-input",
          }}
          {...restFieldProps}
        />
        <Box
          ref={scrollBoxRef}
          sx={{
            maxWidth: width || "85%",
            display: "flex",
            gap: 1,
            overflowX: "auto",
            position: "absolute",
            left: "152px",
            top: "6px",
          }}
        >
          {reverseArray?.map((file, index) => {
            const isPdf = isPDF(file);
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "127px",
                  height: "120px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "8px",
                  backgroundColor: "#f9f9f9",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  position: "relative",
                }}
                onClick={() =>
                  isPdf ? handlePdfClick(file) : handleFileClick(file, index)
                }
              >
                {type === "IMAGE" || !isPdf ? (
                  <Box
                    component="img"
                    src={file}
                    alt={`File Preview ${index + 1}`}
                    sx={{
                      minWidth: "127px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                ) : (
                  <Box
                    component="img"
                    src="/pdf-icon.svg"
                    sx={{
                      minWidth: "127px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                )}
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    zIndex: 1,
                    width: "20px", // Set width to control icon size
                    height: "20px", // Set height to control icon size
                    padding: "0", // Remove default padding
                    "&:hover": {
                      backgroundColor: "darkred", // Change background color on hover
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)", // Darker shadow on hover
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveClick(file);
                  }}
                >
                  <CloseIcon sx={{ fontSize: 16, color: "white" }} />{" "}
                  {/* Adjust fontSize here */}
                </IconButton>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Dialog open={openPdf} onClose={handleClosePdf} maxWidth="lg" fullWidth>
        <DialogTitle>
          PDF Preview
          <IconButton
            aria-label="close"
            onClick={handleClosePdf}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <iframe
            src={currentPdf}
            width="100%"
            height="600px"
            title="PDF Preview"
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
        <DialogContent style={customDialogContentStyle}>
          <DialogContentText>
            <style>{customDotStyles}</style>
            <Slider className="image-slider-popup" {...settings}>
              {filteredImagesFromMixedFiles?.map((image, index) => (
                <div
                  key={image + index}
                  className="flex justify-center relative"
                >
                  <img
                    src={image}
                    alt={`Image ${index}`}
                    className="mx-auto rounded-xl"
                    style={{ width: "730px", height: "665px" }}
                  />
                  <div
                    className={`absolute rounded-full bg-white padding-2.5 top-0 right-0`}
                    onClick={(e) => removeImageHandler(e, image)}
                  >
                    <GetIconFile
                      data={{ width: "24px", height: "24px" }}
                      iconName="remove-icon"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={openConfirmDialog} onClose={handleCancelRemove}>
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this file?
          </DialogContentText>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              onClick={handleCancelRemove}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmRemove}
              color="primary"
              variant="contained"
              sx={{ ml: 2 }}
            >
              Remove
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageUploadField;
