import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from "@mui/material";
import DropdownField from "../question-fields/dropdown";
import NoteField from "../question-fields/note";
import {
  updateCustomNote,
  updateInvoice,
  updateQuestion
} from "../services/finance.service";
import { decode } from "html-entities";

const InvoiceTable = forwardRef(
  ({ invoiceProds, customerID, setInvoiceProds, setIsLoading }, ref) => {
    const [invoiceProdsArray, setInvoiceProdArray] = useState(
      invoiceProds || []
    );
    useImperativeHandle(ref, () => ({
      async performAction() {
        setIsLoading(true);
        const allReviewed = invoiceProdsArray.every(
          (prod) => prod.status === "Yes. Reviewed. Good to go."
        );

        const red = invoiceProdsArray.some(
          (prod) => prod.status === "Yes. Reviewed w/ Open Questions"
        );
        const yellow = invoiceProdsArray.some(
          (prod) => prod.status === "Yes. Not Yet Reviewed"
        );

        // Call the API to update the product data
        const response = await updateInvoice(customerID, {
          invoiceProds: invoiceProdsArray,
          confirmStatus: allReviewed
            ? "Yes. Reviewed. Good to go."
            : red
            ? "Yes. Reviewed w/ Open Questions"
            : "Yes. Not Yet Reviewed"
        });

        // Handle the API response if needed
        if (response?.data?.status) {
          console.log(invoiceProdsArray, "invoiceProdsArray1");
          setInvoiceProds(invoiceProdsArray);
          console.log("Update successful");
          setIsLoading(false);
        } else {
          console.log("Update failed");
          setIsLoading(false);
        }
      }
    }));
    console.log("invoiceProds", invoiceProdsArray);

    const handleChanges = async (field, value, prod) => {
      // Create a new array with the updated product data
      const updatedProdsArray = invoiceProdsArray.map((item) => {
        if (item.id === prod.id) {
          return { ...item, [field]: value };
        }
        return item;
      });

      // Update the local state
      setInvoiceProdArray(updatedProdsArray);
    };

    const columns = [
      { id: (_prod, idx) => idx + 1, label: "NO." },
      { id: "product_name", label: "Product Name", maxWidth: 350 },
      {
        id: "qty",
        label: "Qty",
        align: "right",
        minWidth: 50,
        format: (value) => value.toFixed(2)
      },
      {
        id: "list_price",
        label: "Price",
        align: "right",
        format: (value) => value.toFixed(2),
        minWidth: 50
      },
      {
        id: "amount",
        label: "Total",
        align: "right",
        format: (value) => "$" + value.toFixed(2),
        minWidth: 50
      },
      {
        id: (prod) => {
          let options = [
            "Yes. Reviewed. Good to go.",
            "Yes. Reviewed w/ Open Questions",
            "Yes. Not Yet Reviewed"
          ];
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4
              }}
            >
              <DropdownField
                question={"Status"}
                value={prod?.status || ""}
                options={options}
                handleChangeDropdown={(event) =>
                  handleChanges("status", event.target.value, prod)
                }
              />

              <NoteField
                question={"Notes"}
                value={prod?.note || ""}
                multiline={true}
                maxRows={5}
                minRows={1}
                handleNoteBlur={(event) =>
                  handleChanges("note", event.target.value, prod)
                }
                type={"text"}
              />
            </div>
          );
        },
        label: "Action",
        align: "center",
        maxWidth: 300
      }
    ];

    return (
      <Box sx={{ flexGrow: 1, p: 0.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {invoiceProdsArray.length > 0 ? (
              <Paper
                sx={{
                  width: "100%",
                  overflow: "auto",
                  border: "1px solid #6685ac"
                }}
              >
                <TableContainer
                  style={{
                    maxHeight: "calc(100vh - 250px)",
                    overflowY: "auto"
                  }}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{ borderCollapse: "collapse" }}
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                              // borderColor: "#6685ac",
                              fontWeight: "bold"
                            }}
                            sx={{
                              border: "0.5px solid #6685ac",
                              backgroundColor: "#dae4f0"
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceProdsArray?.map((prod, idx) => {
                        console.log(prod, "prod3");
                        if (!prod.isActive) {
                          // Skip rendering if the product is not active
                          return null;
                        }

                        return (
                          <TableRow tabIndex={-1} key={prod.id || idx}>
                            {columns.map((column) => {
                              const value = prod[column.id];
                              const columnId = column.id;
                              const valueToRender =
                                typeof columnId === "function"
                                  ? columnId(prod, idx)
                                  : value;
                              console.log(column, "column");

                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    maxWidth: column.maxWidth,
                                    borderColor: "#6685ac",
                                    border: "0.5px solid #6685ac"
                                  }}
                                  sx={{
                                    backgroundColor:
                                      column?.label !== "Action"
                                        ? "#eeeeee"
                                        : "white"
                                  }}
                                >
                                  {column.format &&
                                  typeof valueToRender === "number"
                                    ? column.format(valueToRender)
                                    : valueToRender}
                                  {columnId === "product_name" && (
                                    <div className="flex items-center gap-1">
                                      <div className="font-semibold text-[12px] pt-[2px]">
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: decode(
                                              prod?.product_desc || ""
                                            )
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            ) : (
              <div className="flex justify-center items-center text-lg h-full">
                No invoice to display
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default InvoiceTable;
