import { useEffect, useState, createContext } from "react";
import {
  getCustomerById,
  getOpsResultingData,
  updateInvoiceProduct,
  updateNotes,
} from "./services/finance.service";
import StepperForm from "./StepperForm";
import { useSearchParams } from "react-router-dom";
import { getAuth } from "./services/identity.service";
import MuiLinearProgress from "./UI/MuiLinearProgress";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OpenInFullSharp } from "@mui/icons-material";
// Create a context
export const StepperContextProvide = createContext();

const StepperIndexPage = () => {
  const [data, setData] = useState({});
  const [questionData, setQuestionData] = useState([]);
  const [searchParams] = useSearchParams();
  const [customerID, setCustomerID] = useState(null);
  const [invoiceProds, setInvoiceProds] = useState(null);
  const [customNotes, setCustomNotes] = useState(null);
  const [openQuestion, setOpenQuestion] = useState(null);
  const [transitionConfirmation, setTransitionConsirmation] = useState([]);
  const [furnitureItems, setFurnitureItems] = useState([]);
  const [tollGateItems, setTollGateItems] = useState([]);
  const [fvRooms, setFvRooms] = useState([]);
  const [fvFloors, setFloors] = useState([]);

  // useEffect(() => {

  //    // Function to group rooms by floor
  //    const groupRoomsByFloor = (roomsArray) => {
  //     const groupedRooms = {};

  //     roomsArray.forEach((room) => {
  //       const floorName = room.Field_Visit_Floor?.name || "Unknown Floor";

  //       // Initialize the floor in the groupedRooms if it doesn't exist
  //       if (!groupedRooms[floorName]) {
  //         groupedRooms[floorName] = [];
  //       }

  //       // Add the current room to the respective floor group
  //       groupedRooms[floorName].push({
  //         name: room.Name,
  //         isInstall: room.Is_Install,
  //         isRefinishing: room.Is_Refinishing,
  //         sqFt: room.Sq_Ft,
  //         type: room.Type,
  //         // Add more fields as required
  //       });
  //     });

  //     return groupedRooms;
  //   };

  //   // Group the FV_Rooms array
  //   const groupedRooms = groupRoomsByFloor(FV_Rooms);

  //   console.log(groupedRooms);
  // }, [fvRooms]);

  const auth = getAuth();

  const queryValue = searchParams.get("id");
  const fetchCustomerData = async () => {
    const getData = await getCustomerById(queryValue);
    const questionsArray = getData?.data?.entity?.questions;

    setQuestionData(getData?.data?.entity?.questions);
    setCustomerID(getData?.data?.entity?.id);
    setInvoiceProds(getData?.data?.entity?.invoiceProds);
    setCustomNotes(getData?.data?.entity?.customNotes);
    setOpenQuestion(getData?.data?.entity?.openQuestions);
    setTollGateItems(getData?.data?.entity?.tollgateQuestions);
    setTransitionConsirmation(getData?.data?.entity?.transitionQuestions);

    const currentQuestData =
      questionsArray.find(
        (q) => q.question === "Is there furniture in the space?"
      ) || {};
    // Check if currentQuestData is an empty object
    const isEmptyObject = Object.keys(currentQuestData).length === 0;
    const itemsValue = isEmptyObject
      ? []
      : currentQuestData?.details?.["items"] || [];
    if (!isEmptyObject && itemsValue?.length > 0) {
      const updatedItems = itemsValue.map((item) => ({
        item: item.status,
        note: item.note,
        images: item.images,
        zc_po_id: queryValue,
        qty: "",
        size: "",
        in_scope: false,
      }));

      setFurnitureItems(updatedItems);
    } else {
      setFurnitureItems(getData?.data?.entity?.furnitures);
    }
  };

  useEffect(() => {
    // if (!!auth) {
    const fetchData = async () => {
      const getData = await getOpsResultingData(queryValue);
      const opsData = getData?.data?.entity?.details?.output;
      setFvRooms(opsData?.data?.FV_Rooms);
      setFloors(opsData?.data?.FV_Floors);
      if (opsData?.data?.invoice?.length > 0) {
        let productDetails = {
          Product_Details: opsData?.data?.invoice[0]?.Product_Details,
        };
        updateInvoiceProduct(queryValue, productDetails);
        if (opsData?.data?.invoice[0]?.Custom_Notes) {
          let notes = { customNote: opsData?.data?.invoice[0]?.Custom_Notes };
          await updateNotes(queryValue, notes);
        }
      }

      setData(opsData);
      await fetchCustomerData();
    };
    fetchData();

    // }
  }, []);

  console.log(questionData, "data");

  return (
    <>
      <ToastContainer />
      <StepperContextProvide.Provider
        value={{
          data,
          questionData,
          customerID,
          invoiceProds,
          customNotes,
          openQuestion,
          transitionConfirmation,
          furnitureItems,
          setData,
          setQuestionData,
          setInvoiceProds,
          setCustomNotes,
          setOpenQuestion,
          setTransitionConsirmation,
          setFurnitureItems,
          setTollGateItems,
          zc_po_id: queryValue,
          tollGateItems,
          fvRooms,
          fvFloors,
        }}
      >
        {Object.keys(data || {}).length > 0 &&
        Object.keys(questionData || {}).length > 0 ? (
          <div className="App">
            <StepperForm
              data={data?.data}
              questionData={questionData || []}
              id={queryValue}
              setData={setData}
              customerID={customerID}
              invoiceProds={invoiceProds}
              customNotes={customNotes}
              setInvoiceProds={setInvoiceProds}
              setCustomNotes={setCustomNotes}
              openQuestion={openQuestion}
              setOpenQuestion={setOpenQuestion}
              transitionConfirmation={transitionConfirmation}
              setTransitionConsirmation={setTransitionConsirmation}
              zc_po_id={queryValue}
              setQuestionData={setQuestionData}
              tollGateItems={tollGateItems}
            />
          </div>
        ) : (
          <MuiLinearProgress />
        )}
      </StepperContextProvide.Provider>
    </>
  );
};

export default StepperIndexPage;
