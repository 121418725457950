import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DropdownField from "../question-fields/dropdown";
import NoteField from "../question-fields/note";
import {
  deleteOpenQuesion,
  updateOpenQuestion
} from "../services/finance.service";
import { Delete } from "@mui/icons-material";

const OpenQuestions = forwardRef(
  (
    {
      customerID,
      openQuestion,
      setOpenQuestion,
      setIsLoading,
      fieldsToRender,
      questData
    },
    ref
  ) => {
    const singleQuestion = {
      question: "",
      salesNote: "",
      status: "PENDING",
      customerId: customerID
    };

    const [opnQuestion, setOpnQuestion] = useState(openQuestion || []);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    useEffect(() => {
      setOpnQuestion(openQuestion);
    }, [openQuestion]);

    const handleAddQuestion = () => {
      setOpnQuestion([...opnQuestion, singleQuestion]);
    };

    const handleChanges = async (field, value, prod, index) => {
      const updatedProdsArray = opnQuestion.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return item;
      });
      setOpnQuestion(updatedProdsArray);
    };

    const handleDelete = (props, index) => {
      setDeleteIndex(index);
      setOpenDialog(true);
    };

    const confirmDelete = async () => {
      setIsLoading(true);
      if (opnQuestion?.[deleteIndex]?.id) {
        let response = await deleteOpenQuesion(
          customerID,
          opnQuestion?.[deleteIndex]?.id
        );
        if (response?.data?.status) {
          const updatedProdsArray = opnQuestion.filter(
            (_, idx) => idx !== deleteIndex
          );
          setOpnQuestion(updatedProdsArray);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        const updatedProdsArray = opnQuestion.filter(
          (_, idx) => idx !== deleteIndex
        );
        setOpnQuestion(updatedProdsArray);
        setIsLoading(false);
      }

      setOpenDialog(false);
      setDeleteIndex(null);
    };

    let field = {
      question: "Open Questions Needed?",
      key: "status",
      type: "DROPDOWN",
      option: ["Yes Needed", "Not Needed"]
    };
    const currentQuestData =
      questData.find((q) => q.question === field?.question) || {};

    // Check if currentQuestData is an empty object
    const isEmptyObject = Object.keys(currentQuestData).length === 0;

    // Use isEmptyObject to determine if the object is empty
    const valueKey = field?.key;
    const value = isEmptyObject ? "" : currentQuestData[valueKey] || "";

    useImperativeHandle(ref, () => ({
      async performAction() {
        setIsLoading(true);
        const allReviewed = opnQuestion.every((prod) => prod.status === "DONE");

        const response = await updateOpenQuestion(customerID, {
          openQuestions: value === "Not Needed" ? [] : opnQuestion,
          confirmStatus:
            value === "Not Needed"
              ? "Not Needed"
              : opnQuestion?.length == 0
              ? null
              : allReviewed
              ? "DONE"
              : "PENDING",
        });

        if (response?.data?.status) {
          setOpenQuestion(response?.data?.entity);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    }));

    const columns = [
      { id: (_prod, idx) => idx + 1, label: "NO.", maxWidth: 1 },
      {
        id: (prod, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4
            }}
          >
            <NoteField
              question={""}
              value={prod?.question || ""}
              handleNoteBlur={(event) =>
                handleChanges("question", event.target.value, prod, index)
              }
              type={"text"}
            />
          </div>
        ),
        label: "Questions",
        align: "center",
        minWidth: 150
      },
      {
        id: (prod, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4
            }}
          >
            <NoteField
              question={""}
              value={prod?.salesNote || ""}
              multiline={true}
              maxRows={5}
              minRows={1}
              handleNoteBlur={(event) =>
                handleChanges("salesNote", event.target.value, prod, index)
              }
              type={"text"}
            />
          </div>
        ),
        label: "Sales Notes",
        align: "center",
        minWidth: 150
      },
      {
        id: (prod, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4
            }}
          >
            <Checkbox
              checked={prod.status === "PENDING" ? false : true}
              onChange={(e) =>
                handleChanges(
                  "status",
                  e.target.checked ? "DONE" : "PENDING",
                  prod,
                  index
                )
              }
            />
          </div>
        ),
        label: "Ops Sign Off",
        align: "center",
        maxWidth: 20
      },
      {
        id: (prod, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <IconButton onClick={() => handleDelete(prod, index)}>
              <Delete />
            </IconButton>
          </div>
        ),
        label: "Delete",
        align: "center",
        maxWidth: 5
      }
    ];

    return (
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} sx={{ paddingTop: 1 }}>
          <Grid item xs={12} key={"Open Question"}>
            {fieldsToRender(field, field?.question)}
          </Grid>
        </Grid>

        {value === "Yes Needed" && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddQuestion}
              startIcon={<AddIcon />}
            >
              Add Question
            </Button>
            <Grid container spacing={2} sx={{ paddingTop: 1 }}>
              <Grid item xs={12} md={12}>
                {opnQuestion.length > 0 ? (
                  <Paper
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      border: "1px solid #6685ac"
                    }}
                  >
                    <TableContainer
                      style={{
                        maxHeight: "calc(100vh - 300px)",
                        overflowY: "auto"
                      }}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{ borderCollapse: "collapse" }}
                      >
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.label}
                                align={column.align}
                                style={{
                                  maxWidth: column.maxWidth,
                                  minWidth: column.minWidth,
                                  fontWeight: "bold"
                                }}
                                sx={{
                                  border: "0.5px solid #6685ac",
                                  backgroundColor: "#dae4f0"
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {opnQuestion?.map((prod, idx) => (
                            <TableRow tabIndex={-1} key={prod.id || idx}>
                              {columns.map((column) => {
                                const value = prod[column.id];
                                const columnId = column.id;
                                const valueToRender =
                                  typeof columnId === "function"
                                    ? columnId(prod, idx)
                                    : value;

                                return (
                                  <TableCell
                                    key={column.label}
                                    align={column.align}
                                    style={{
                                      maxWidth: column.maxWidth,
                                      borderColor: "#6685ac",
                                      border: "0.5px solid #6685ac"
                                    }}
                                  >
                                    {column.format &&
                                    typeof valueToRender === "number"
                                      ? column.format(valueToRender)
                                      : valueToRender}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ) : (
                  <div className="flex justify-center items-center text-lg h-full"></div>
                )}
              </Grid>
            </Grid>
          </>
        )}
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this question?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
);

export default OpenQuestions;
