import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const MuiCheckbox = ({ value, name, onChange, label }) => {
  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={onChange} name={name} />}
        label={label}
        sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
      />
    </>
  );
};

export default MuiCheckbox;
