import { TableCell } from "@mui/material";
import React, { useCallback } from "react";

const FurnitureTableRow = ({ prod, column, idx }) => {
  const value = prod[column.id];
  const columnId = column.id;

  const callBackFn = useCallback(() => {
    return columnId(prod, idx);
  }, [prod]);

  const valueToRender = typeof columnId === "function" ? callBackFn() : value;

  return (
    <TableCell
      key={column.label}
      align={column.align}
      style={{
        maxWidth: column.maxWidth,
        borderColor: "#6685ac",
        border: "0.5px solid #6685ac",
      }}
    >
      {column.format && typeof valueToRender === "number"
        ? column.format(valueToRender)
        : valueToRender}
    </TableCell>
  );
};

export default FurnitureTableRow;
