import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import InitialInvoicePdf from "./InitialInvoicePdf";
import DeliveryTicket from "./DeliveryTicket";

const QuestionForm = ({
  fieldsToRender,
  dropdownValue,
  QuestionFormField,
  activeStep,
  questData
}) => {
  const leftSide = QuestionFormField?.[0];
  const righSide = QuestionFormField?.[1];
  console.log("left---", leftSide, QuestionFormField);
  return (
    <Box
      sx={{
        pb: "10px",
        width: "100%"
      }}
    >
      <Grid container spacing={2}>
        {activeStep === 5 ? (
          // Split mainField into two halves

          <React.Fragment>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {leftSide?.map((field, idx) => {
                  const qKey = leftSide[0]?.question + field.key + idx;
                  return (
                    <Grid item xs={field.grid || 12} key={qKey}>
                      {fieldsToRender(field, leftSide[0]?.question)}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {righSide?.map((field, idx) => {
                  const qKey = righSide[0]?.question + field.key + idx;
                  return (
                    <Grid item xs={field.grid || 12} key={qKey}>
                      {fieldsToRender(field, righSide[0]?.question)}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </React.Fragment>
        ) : activeStep === 2 ? (
          <DeliveryTicket
            fieldsToRender={fieldsToRender}
            QuestionFormField={QuestionFormField}
            activeStep={activeStep}
            questData={questData}
          />
        ) : activeStep === 7 ? (
          <React.Fragment>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {leftSide?.map((field, idx) => {
                  const qKey = leftSide[0]?.question + field.key + idx;
                  return (
                    <Grid item xs={field.grid || 12} key={qKey}>
                      {fieldsToRender(field, leftSide[0]?.question)}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {righSide?.map((field, idx) => {
                  const qKey = righSide[0]?.question + field.key + idx;
                  return (
                    <Grid item xs={field.grid || 12} key={qKey}>
                      {fieldsToRender(field, righSide[0]?.question)}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </React.Fragment>
        ) : activeStep === 3 ? (
          <React.Fragment>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {leftSide?.map((field, idx) => {
                  const qKey = leftSide[0]?.question + field.key + idx;
                  return (
                    <Grid item xs={field.grid || 12} key={qKey}>
                      {fieldsToRender(field, leftSide[0]?.question)}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {righSide?.map((field, idx) => {
                  const qKey = righSide[0]?.question + field.key + idx;
                  return (
                    <Grid item xs={field.grid || 12} key={qKey}>
                      {fieldsToRender(field, righSide[0]?.question)}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          QuestionFormField?.map((mainField, mainInx) => {
            // console.log("mainField", mainField, activeStep, QuestionFormField)

            return mainField?.map((field, idx) => {
              const qKey = QuestionFormField[0]?.question + field.key + idx;

              return (
                <Grid item xs={field.grid || 12} key={qKey}>
                  {fieldsToRender(field, mainField[0]?.question)}
                </Grid>
              );
            });
          })
        )}
      </Grid>
      {activeStep === 1 && (
        <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
          <InitialInvoicePdf />
        </Box>
      )}
    </Box>
  );
};

export default QuestionForm;

QuestionForm.propTypes = {
  fieldsToRender: PropTypes.func,
  dropdownValue: PropTypes.object
};
