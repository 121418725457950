import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";
import { StepperContextProvide } from "../..";
import ImageSlider from "../../UI/ImageSlider";

const noFurnitureScopeStyle = {
  width: "100%",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid black",
  borderStyle: "dashed",
  fontSize: "18px"
};

const FurnitureAccordian = ({ selectedTab }) => {
  const columns = useMemo(
    () => [
      {
        id: (_prod, idx) => idx + 1,
        label: "NO.",
        align: "center",
        maxWidth: 2
      },
      { id: (prod) => prod.item, label: "Item", align: "center", maxWidth: 20 },
      { id: (prod) => prod.size, label: "Size", align: "center", maxWidth: 10 },
      {
        id: (prod) => prod.qty,
        label: "Quantity",
        align: "center",
        maxWidth: 10
      },
      {
        id: (prod) => prod.note,
        label: "Notes",
        align: "center",
        maxWidth: 30
      },
      {
        id: (prod) => (
          <div style={{ display: "flex", justifyContent: "start", gap: 4 }}>
            <Box sx={{ display: "flex", gap: 1, overflowX: "auto" }}>
              {prod.images?.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "127px",
                    height: "120px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "8px",
                    backgroundColor: "#f9f9f9"
                  }}
                  onClick={() => handleFileClick(prod.images, index)}
                >
                  <Box
                    component="img"
                    src={file}
                    alt={`File Preview ${index + 1}`}
                    sx={{
                      minWidth: "127px",
                      height: "120px",
                      objectFit: "cover"
                    }}
                  />
                </Box>
              ))}
            </Box>
          </div>
        ),
        label: "Images",
        align: "center",
        maxWidth: 100
      }
    ],
    []
  );

  const { furnitureItems, fvRooms, fvFloors } = useContext(
    StepperContextProvide
  );
  const [fImages, setFImages] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const handleFileClick = (images, index) => {
    setOpenDialog(true);
    setCurrentFileIndex(index);
    setFImages(images);
  };

  const getRoomName = (roomId) =>
    fvRooms.find((r) => r.id === roomId)?.Display_Name || "";
  const getFloorName = (floorId) =>
    fvFloors.find((f) => f.id === floorId)?.Name || "";

  // Group furniture by floor and room and handle "No Space" and "No Room"
  const floorAndRoomwiseFurnitures = useMemo(() => {
    let filteredFurnitureItems = furnitureItems;

    // Apply filtering based on `selectedTab` if selectedTab === 1
    if (selectedTab === 1) {
      filteredFurnitureItems = filteredFurnitureItems.filter((f) => f.in_scope);
    }

    const noSpaceFurniture = [];
    const furnitureByFloor = {};

    filteredFurnitureItems.forEach((item) => {
      if (!item.floorId && !item.roomId) {
        // No Space Furniture
        noSpaceFurniture.push(item);
      } else if (item.floorId && !item.roomId) {
        // No Room Furniture
        if (!furnitureByFloor[item.floorId]) {
          furnitureByFloor[item.floorId] = {
            rooms: { noRoom: [] },
            floorId: item?.floorId
          };
        }
        furnitureByFloor[item.floorId].rooms?.noRoom?.push(item);
      } else if (item.floorId && item.roomId) {
        // Roomwise Furniture
        if (!furnitureByFloor[item.floorId]) {
          furnitureByFloor[item.floorId] = { rooms: {}, floorId: item.floorId };
        }
        if (!furnitureByFloor[item.floorId].rooms[item.roomId]) {
          furnitureByFloor[item.floorId].rooms[item.roomId] = [];
        }
        furnitureByFloor[item.floorId].rooms[item.roomId]?.push(item);
      }
    });

    // Move "No Room Furniture" to the end of each floor
    const formattedFurniture = Object.values(furnitureByFloor).map((floor) => {
      const floorRooms = { ...floor.rooms };
      const noRoomFurniture = floorRooms.noRoom || null;
      delete floorRooms.noRoom; // Remove noRoom before reinserting at the end

      return {
        floorName: getFloorName(floor.floorId),
        rooms: {
          ...floorRooms,
          ...(noRoomFurniture ? { noRoom: noRoomFurniture } : {})
        }
      };
    });

    // "No Space Furniture" should be rendered last
    const furnitureWithNoSpace = noSpaceFurniture.length
      ? { floorName: "No Space Furniture", rooms: noSpaceFurniture }
      : null;

    return [...formattedFurniture, furnitureWithNoSpace].filter(Boolean); // Filter out null values
  }, [furnitureItems, fvFloors, fvRooms, selectedTab]);

  return (
    <Box sx={{ width: "100%" }}>
      {floorAndRoomwiseFurnitures.length ? (
        floorAndRoomwiseFurnitures.map((floor, floorIndex) => (
          <Accordion key={floorIndex}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{floor.floorName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Array.isArray(floor.rooms) ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="furniture table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.label}
                            align={column.align}
                            sx={{
                              maxWidth: column.maxWidth,
                              border: "0.5px solid #6685ac",
                              backgroundColor: "#dae4f0"
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {floor.rooms.map((prod, index) => (
                        <TableRow key={index}>
                          {columns.map((column, colIndex) => (
                            <TableCell
                              key={colIndex}
                              align={column.align}
                              sx={{
                                maxWidth: column.maxWidth,
                                borderColor: "#6685ac",
                                border: "0.5px solid #6685ac"
                              }}
                            >
                              {typeof column.id === "function"
                                ? column.id(prod, index)
                                : prod[column.id]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                Object.keys(floor.rooms).map((roomId, roomIndex) => (
                  <Accordion key={roomIndex}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>
                        {roomId === "noRoom"
                          ? "No Room Furniture"
                          : getRoomName(roomId)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="furniture table"
                        >
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.label}
                                  align={column.align}
                                  sx={{
                                    maxWidth: column.maxWidth,
                                    border: "0.5px solid #6685ac",
                                    backgroundColor: "#dae4f0"
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {floor.rooms[roomId].map((prod, index) => (
                              <TableRow key={index}>
                                {columns.map((column, colIndex) => (
                                  <TableCell
                                    key={colIndex}
                                    align={column.align}
                                    sx={{
                                      maxWidth: column.maxWidth,
                                      borderColor: "#6685ac",
                                      border: "0.5px solid #6685ac"
                                    }}
                                  >
                                    {typeof column.id === "function"
                                      ? column.id(prod, index)
                                      : prod[column.id]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Box>
          <div style={noFurnitureScopeStyle}>No furnitures in scope</div>
        </Box>
      )}
      <ImageSlider
        openDialog={openDialog}
        filteredImagesFromMixedFiles={fImages}
        currentFileIndex={currentFileIndex}
        handleCloseDialog={() => setOpenDialog(false)}
      />
    </Box>
  );
};

export default FurnitureAccordian;
