import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import DropdownField from "../question-fields/dropdown";
import NoteField from "../question-fields/note";
import { Delete } from "@mui/icons-material";
import {
  deleteTollGate,
  updateCustomerTollGate,
} from "../services/finance.service";
import { StepperContextProvide } from "..";
import { toast } from "react-toastify";
import CustomSelect from "../question-fields/customSelect";

const OPR = forwardRef(
  (
    {
      fieldsToRender,
      dropdownValue,
      QuestionFormField,
      activeStep,
      questData,
      setIsLoading,
    },
    ref
  ) => {
    const { zc_po_id, customerID, tollGateItems, setTollGateItems } =
      useContext(StepperContextProvide);
    const [tollGate, setTollGate] = useState([
      {
        type: "TOLLGATE",
        status: "",
        note: "",
        other_option: "",
        customerId: customerID,
      },
      { type: "CUSTOM_NOTE", note: "", customerId: customerID },
    ]);

    const tollGateOps = [
      "Stain Sample Selection",
      "Stain Samples to be confirmed for Matching Existing Spaces",
      "Confirm Subfloor Integrity Prior to Installation",
      "Finish Gloss Level Confirmation",
      "Other",
    ];

    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [selectedOption, setSelectedOption] = React.useState([]);

    useEffect(() => {
      setTollGate(tollGateItems);
    }, []);

    const singleTollGate = {
      type: "TOLLGATE",
      status: "",
      note: "",
      customerId: customerID,
    };

    const handleAdd = (val) => {
      if (val == "tollgate") {
        setTollGate([...tollGate, singleTollGate]);
      } else {
        setTollGate([
          ...tollGate,
          { type: "CUSTOM_NOTE", note: "", status: "", customerId: customerID },
        ]);
      }
    };

    const handleChanges = async (field, value, prod, index) => {
      const updatedProdsArray = tollGate.map((item, idx) => {
        if (idx === index && item?.type === prod?.type) {
          return { ...item, [field]: value };
        }
        return item;
      });

      setTollGate(updatedProdsArray);
    };
    // Function to filter out objects with empty 'status' or 'note'
    // const filterData = (data) => {
    //   return data.filter((item) => {
    //     // Keep TOLLGATE objects only if 'status' is not empty
    //     if (item.type === "TOLLGATE" && item.status === "") {
    //       return false;
    //     }
    //     // Keep CUSTOM_NOTE objects only if 'note' is not empty
    //     if (item.type === "CUSTOM_NOTE" && item.note === "") {
    //       return false;
    //     }
    //     // Keep all other objects
    //     return true;
    //   });
    // };

    // // Clean up the data
    // const filteredData = filterData(tollGate);
    // console.log(filteredData, "cleanedData");

    const handleDelete = (index) => {
      setDeleteIndex(index);
      setOpenDialog(true);
    };

    const confirmDelete = async () => {
      if (tollGate?.[deleteIndex]?.id) {
        let response = await deleteTollGate(tollGate?.[deleteIndex]?.id);
        if (response?.data?.status) {
          const updatedProdsArray = tollGate.filter(
            (_, idx) => idx !== deleteIndex
          );
          setTollGate(updatedProdsArray);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        const updatedProdsArray = tollGate.filter(
          (_, idx) => idx !== deleteIndex
        );
        setTollGate(updatedProdsArray);
        setIsLoading(false);
      }
      setOpenDialog(false);
      setDeleteIndex(null);
    };

    useImperativeHandle(ref, () => ({
      async performAction() {
        setIsLoading(true);
        let body = {};
        // Send cleanedData to the API

        if (tollGate?.length > 0) {
          const responseTollGate = await updateCustomerTollGate(customerID, {
            tollgateQuestions: tollGate,
          });
          if (responseTollGate?.status) {
            setTollGate(responseTollGate?.data?.entity);
            setTollGateItems(responseTollGate?.data?.entity);
          } else {
            toast.error(responseTollGate?.message || "Something went wrong");
          }
        }
      },
    }));

    return (
      <>
        <Box
          sx={{
            pb: "10px",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <React.Fragment>
              {/* Left Grid Item */}
              <Grid item xs={5.9}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAdd("tollgate")}
                >
                  + Toll gate
                </Button>
                <Grid
                  container
                  spacing={2}
                  sx={{ maxHeight: "65vh", overflowY: "auto", marginTop: "0" }}
                >
                  {tollGate?.map((field, idx) => {
                    return (
                      <>
                        {" "}
                        {field?.type === "TOLLGATE" && (
                          <Grid
                            item
                            xs={12}
                            sx={{ mt: 2 }}
                            key={field?.status + idx}
                          >
                            <Grid container spacing={1} alignItems="center">
                              {/* TollGate Dropdown Field */}
                              <Grid item xs={5.5}>
                                <Grid container spacing={1}>
                                  <Grid
                                    item
                                    xs={field?.status === "Other" ? 6 : 12}
                                  >
                                    <DropdownField
                                      question="TollGate"
                                      value={field?.status}
                                      options={tollGateOps}
                                      handleChangeDropdown={(event) =>
                                        handleChanges(
                                          "status",
                                          event.target.value,
                                          field,
                                          idx
                                        )
                                      }
                                      selectedBackgroundColor="red"
                                    />
                                  </Grid>
                                  {field?.status === "Other" && (
                                    <Grid item xs={6}>
                                      <NoteField
                                        question={"Other Tollgate"}
                                        value={field?.other_option || ""}
                                        handleNoteBlur={(event) =>
                                          handleChanges(
                                            "other_option",
                                            event.target.value,
                                            field,
                                            idx
                                          )
                                        }
                                        type={"text"}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>

                              {/* Note Field */}
                              <Grid item xs={5.5}>
                                <NoteField
                                  question={"Note"}
                                  value={field?.note || ""}
                                  handleNoteBlur={(event) =>
                                    handleChanges(
                                      "note",
                                      event.target.value,
                                      field,
                                      idx
                                    )
                                  }
                                  type={"text"}
                                />
                              </Grid>

                              {/* Delete Icon Button */}
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton onClick={() => handleDelete(idx)}>
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </Grid>
              </Grid>

              {/* Vertical Divider */}
              <Grid
                item
                xs={0.1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <Divider orientation="vertical" flexItem />
              </Grid>

              {/* Right Grid Item */}
              <Grid item xs={5.8}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAdd("customeNote")}
                >
                  + Custom Note
                </Button>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    maxHeight: "65vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    marginTop: "0",
                  }}
                >
                  {tollGate?.map((field, idx) => {
                    return (
                      <>
                        {field?.type === "CUSTOM_NOTE" && (
                          <Grid
                            item
                            xs={12}
                            sx={{ mt: 2 }}
                            key={field?.note + idx}
                          >
                            <Grid container spacing={2} alignItems="center">
                              {/* Note Field */}
                              <Grid item xs={11}>
                                <NoteField
                                  question={"Note"}
                                  value={field?.note || ""}
                                  handleNoteBlur={(event) =>
                                    handleChanges(
                                      "note",
                                      event.target.value,
                                      field,
                                      idx
                                    )
                                  }
                                  type={"text"}
                                />
                              </Grid>

                              {/* Delete Icon Button */}
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton onClick={() => handleDelete(idx)}>
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </React.Fragment>
          </Grid>
        </Box>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default OPR;
