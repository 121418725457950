import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DropdownField from "../question-fields/dropdown";
import NoteField from "../question-fields/note";
import {
  deleteTransition,
  updateOpenQuestion,
  updateTransition,
  uploadImage
} from "../services/finance.service";
import { Delete } from "@mui/icons-material";
import ImageUploadField from "../question-fields/ImageUploadField";

const TransitionConfirmation = forwardRef(
  (
    {
      customerID,
      transitionConfirmation,
      setTransitionConsirmation,
      setIsLoading,
      fieldsToRender,
      questData,
      setQuestData
    },
    ref
  ) => {
    const singleQuestion = {
      status: "",
      note: "",
      images: [],
      customerId: customerID
    };

    const [transitionConfirm, setTransitionConfirm] = useState(
      transitionConfirmation || []
    );
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    console.log("transitionConfirm", transitionConfirm);

    useEffect(() => {
      setTransitionConfirm(transitionConfirmation);
    }, [transitionConfirmation]);

    const handleAddQuestion = () => {
      setTransitionConfirm([...transitionConfirm, singleQuestion]);
    };

    const handleImageChange = async (selectedFiles, index) => {
      setIsLoading(true);

      let formData = new FormData();
      formData.append("file", selectedFiles[0]);
      formData.append("originalname", selectedFiles[0]?.name);

      let imageUploaded = await uploadImage(formData);
      if (imageUploaded.data.status) {
        handleChanges(
          "images",
          [...transitionConfirm[index]?.images, imageUploaded?.data?.entity],
          null,
          index
        );
        setIsLoading(false);
      }
    };

    const handleChanges = async (field, value, prod, index) => {
      const updatedProdsArray = transitionConfirm.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return item;
      });

      setTransitionConfirm(updatedProdsArray);
    };

    const handleDelete = (index) => {
      setDeleteIndex(index);
      setOpenDialog(true);
    };

    const confirmDelete = async () => {
      if (transitionConfirm?.[deleteIndex]?.id) {
        let response = await deleteTransition(
          customerID,
          transitionConfirm?.[deleteIndex]?.id
        );
        if (response?.data?.status) {
          const updatedProdsArray = transitionConfirm.filter(
            (_, idx) => idx !== deleteIndex
          );

          setTransitionConfirm(updatedProdsArray);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        const updatedProdsArray = transitionConfirm.filter(
          (_, idx) => idx !== deleteIndex
        );

        setTransitionConfirm(updatedProdsArray);
        setIsLoading(false);
      }

      setOpenDialog(false);
      setDeleteIndex(null);
    };

    let field = {
      question: "Transition Confirmation Needed?",
      key: "status",
      type: "DROPDOWN",
      option: ["Yes Needed", "Not Needed"]
    };
    const currentQuestData =
      questData.find((q) => q.question === field?.question) || {};

    // Check if currentQuestData is an empty object
    const isEmptyObject = Object.keys(currentQuestData).length === 0;

    // Use isEmptyObject to determine if the object is empty
    const valueKey = field?.key;
    const value = isEmptyObject ? "" : currentQuestData[valueKey] || "";

    useImperativeHandle(ref, () => ({
      async performAction() {
        setIsLoading(true);

        const red = transitionConfirm.some(
          (prod) =>
            prod.status === "Invoice : Reviewed w/ Open Questions" ||
            prod.status === "Ops : Reviewed w/ Open Questions"
        );
        const allReviewed = transitionConfirm.every(
          (prod) =>
            prod.status === "Invoice : Reviewed and Approved" ||
            prod.status === "Ops : Reviewed and Approved"
        );

        const response = await updateTransition(customerID, {
          transitionQuestions: value === "Not Needed" ? [] : transitionConfirm,
          confirmStatus:
            value === "Not Needed"
              ? "Not Needed"
              : transitionConfirm?.length === 0
              ? null
              : allReviewed
              ? "Invoice : Reviewed and Approved"
              : red
              ? "Invoice : Reviewed w/ Open Questions"
              : null
        });

        if (response?.data?.status) {
          setTransitionConsirmation(response?.data?.entity);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    }));

    const handleRemoveFiles = async (file, index) => {
      setIsLoading(true);
      let transitions = transitionConfirm;
      if (transitionConfirm?.[index]?.id) {
        let filteredImages = transitions[index]?.images.filter(
          (files, idx) => files !== file
        );

        transitions[index].images = filteredImages;

        const allReviewed = transitions.every(
          (prod) => prod.status === "Reviewed and Approved"
        );
        const response = await updateTransition(customerID, {
          transitionQuestions: transitions,
          confirmStatus: allReviewed
        });
        if (response?.data?.status) {
          setTransitionConsirmation(response?.data?.entity);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        let filteredImages = transitions?.images.filter(
          (files, idx) => files !== file
        );
        transitions.images = filteredImages;
        setTransitionConsirmation(transitions);
        setIsLoading(false);
      }
    };

    const columns = [
      { id: (_prod, idx) => idx + 1, label: "NO.", maxWidth: 1 },
      {
        id: (prod, index) => {
          let options = [
            "Invoice : Reviewed and Approved",
            "Invoice : Reviewed w/ Open Questions",
            "Ops : Reviewed and Approved",
            "Ops : Reviewed w/ Open Questions"
          ];
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4
              }}
            >
              <DropdownField
                question={""}
                value={prod?.status || ""}
                options={options}
                handleChangeDropdown={(event) =>
                  handleChanges("status", event.target.value, prod, index)
                }
              />
            </div>
          );
        },
        label: "Status",
        align: "center",
        maxWidth: 50
      },
      {
        id: (prod, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4
              }}
            >
              <NoteField
                question={""}
                value={prod?.note || ""}
                multiline={true}
                maxRows={5}
                minRows={1}
                handleNoteBlur={(event) =>
                  handleChanges("note", event.target.value, prod, index)
                }
                type={"text"}
              />
            </div>
          );
        },
        label: "Notes",
        align: "center",
        maxWidth: 60
      },
      {
        id: (prod, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 4
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} key={prod?.question + index}>
                  <ImageUploadField
                    handleImageChange={(selectedFiles) =>
                      handleImageChange(selectedFiles, index)
                    }
                    width="73%"
                    name="imageUpload"
                    id="imageUpload"
                    files={prod.images || []}
                    accept="image/*"
                    type={"IMAGE"}
                    imgUploading={false}
                    handleRemoveFiles={(file) => handleRemoveFiles(file, index)}
                  />
                </Grid>
              </Grid>
            </div>
          );
        },
        label: "Images",
        align: "center",
        minWidth: 70
      },
      {
        id: (prod, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <IconButton onClick={() => handleDelete(index)}>
                <Delete />
              </IconButton>
            </div>
          );
        },
        label: "Delete",
        align: "center",
        maxWidth: 5
      }
    ];

    console.log(value, "value--", currentQuestData);
    return (
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} sx={{ paddingTop: 1 }}>
          <Grid item xs={12} key={"Open Question"}>
            {fieldsToRender(field, field?.question)}
          </Grid>
        </Grid>
        {value === "Yes Needed" && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddQuestion}
              startIcon={<AddIcon />}
            >
              Add Transition
            </Button>
            <Grid container spacing={2} sx={{ paddingTop: 1 }}>
              <Grid item xs={12} md={12}>
                {transitionConfirm.length > 0 ? (
                  <Paper
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      border: "1px solid #6685ac"
                    }}
                  >
                    <TableContainer
                      style={{
                        maxHeight: "calc(100vh - 300px)",
                        overflowY: "auto"
                      }}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{ borderCollapse: "collapse" }}
                      >
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.label}
                                align={column.align}
                                style={{
                                  maxWidth: column.maxWidth,
                                  minWidth: column.minWidth,
                                  fontWeight: "bold"
                                }}
                                sx={{
                                  border: "0.5px solid #6685ac",
                                  backgroundColor: "#dae4f0"
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transitionConfirm?.map((prod, idx) => (
                            <TableRow tabIndex={-1} key={prod.id || idx}>
                              {columns.map((column) => {
                                const value = prod[column.id];
                                const columnId = column.id;
                                const valueToRender =
                                  typeof columnId === "function"
                                    ? columnId(prod, idx)
                                    : value;
                                return (
                                  <TableCell
                                    key={column.label}
                                    align={column.align}
                                    style={{
                                      maxWidth: column.maxWidth,
                                      borderColor: "#6685ac",
                                      border: "0.5px solid #6685ac"
                                    }}
                                  >
                                    {column.format &&
                                    typeof valueToRender === "number"
                                      ? column.format(valueToRender)
                                      : valueToRender}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ) : (
                  <div className="flex justify-center items-center text-lg h-full"></div>
                )}
              </Grid>
            </Grid>
          </>
        )}

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this transition?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
);

export default TransitionConfirmation;
