import { Box, Grid } from "@mui/material";
import React from "react";
import { getColorForButton } from "../utils/customeColor";
import { isProjectDataStatusconfirmed } from "../utils/mapping";

let Icons = {
  E: (c) => c.email_confirmed,
  D: (c) => {
    let optionToConsider = "";
    if (
      c.delivery_ticket_confirmed === "Yes. Complete" &&
      (c.supplier_receipt_confirmed === "Yes. Complete" ||
        c.supplier_receipt_confirmed === "Not Needed" ||
        c.supplier_receipt_confirmed === null)
    ) {
      optionToConsider = "Yes. Complete";
    } else if (
      c.delivery_ticket_confirmed === "Yes. In Process" ||
      c.supplier_receipt_confirmed === "Yes. In Process"
    ) {
      optionToConsider = "Yes. In Process";
    } else if (
      c.delivery_ticket_confirmed === "Yes. Not Yet Started" ||
      c.supplier_receipt_confirmed === "Yes. Not Yet Started"
    ) {
      optionToConsider = "Yes. Not Yet Started";
    } else if (c.delivery_ticket_confirmed === "Not Needed") {
      optionToConsider = "Not Needed";
    }

    return optionToConsider;
  },

  "C/B": (c) => c.coi_confirmed,

  PD: (c) => {
    let optionToConsider = "";

    // if (
    //   (c.matterport_confirmed === "Yes. Reviewed. Good to go." ||
    //     c.magicplan_confirmed === "Yes. Reviewed. Good to go.") &&
    //   (!projectDataExcludeOptions.includes(c.matterport_confirmed) ||
    //     c.matterport_confirmed === null ||
    //     c.matterport_confirmed === "No existing file") &&
    //   (!projectDataExcludeOptions.includes(c.magicplan_confirmed) ||
    //     c.magicplan_confirmed === null ||
    //     c.magicplan_confirmed === "No existing file")
    // ) {
    //   optionToConsider = "Yes. Reviewed. Good to go.";
    // }

    if (c.matterport_confirmed === null && c.magicplan_confirmed === null) {
      optionToConsider = "PENDING";
    } else if (isProjectDataStatusconfirmed(c)) {
      optionToConsider = "Yes. Reviewed. Good to go.";
    } else if (
      c.matterport_confirmed === "Yes. Reviewed w/ Open Questions" ||
      c.magicplan_confirmed === "Yes. Reviewed w/ Open Questions"
    ) {
      optionToConsider = "Yes. Reviewed w/ Open Questions";
    } else if (
      c.matterport_confirmed === "Yes. Not Yet Reviewed" ||
      c.magicplan_confirmed === "Yes. Not Yet Reviewed"
    ) {
      optionToConsider = "Yes. Not Yet Reviewed";
    } else if (
      c.matterport_confirmed === "No existing file" &&
      c.magicplan_confirmed === "No existing file"
    ) {
      optionToConsider = "No existing file";
    }

    return optionToConsider;
  },

  TA: (c) => c.transition_confirmed,
  RA: (c) => c.sf_confirmed,
  FA: (c) => c.furniture_confirmed,
  INV: (c) => c.invoice_confirmed,
  CN: (c) => c.custom_notes_confirmed,
  OQ: (c) => c.open_questions_confirmed
};

const ConfirmationStatus = ({ confirmations }) => {
  return (
    <Box sx={{ py: 1 }}>
      <Grid container spacing={1}>
        {Object.keys(Icons).map((key, idx) => {
          let value;
          if (!confirmations) {
            value = null;
          } else {
            value = Icons[key](confirmations);
          }

          return (
            <Grid item key={idx + key}>
              <Box
                sx={{
                  width: "5px",
                  height: "5px",
                  fontSize: "11px",
                  // border: `1px solid ${value ? "green" : "red"}`,
                  backgroundColor: getColorForButton(value),
                  color: "white",
                  borderRadius: "50%",
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {key}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ConfirmationStatus;
