import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from "@mui/material";
import DropdownField from "../question-fields/dropdown";
import NoteField from "../question-fields/note";
import {
  updateCustomNote,
  updateInvoice,
  updateQuestion
} from "../services/finance.service";

const CustomNoteTable = forwardRef(
  ({ customerID, customNotes, setCustomNotes, setIsLoading }, ref) => {
    const [customNotesArray, setCustomNotesArray] = useState(customNotes || []);

    console.log("customNotes1", customNotes, customNotesArray);

    useImperativeHandle(ref, () => ({
      async performAction() {
        setIsLoading(true);
        const allReviewed = customNotesArray.every(
          (prod) => prod.status === "Yes. Reviewed. Good to go."
        );

        const red = customNotesArray.some(
          (prod) => prod.status === "Yes. Reviewed w/ Open Questions"
        );

        // Call the API to update the product data
        const response = await updateCustomNote(customerID, {
          customNotes: customNotesArray,
          confirmStatus: allReviewed
            ? "Yes. Reviewed. Good to go."
            : red
            ? "Yes. Reviewed w/ Open Questions"
            : "Yes. Not Yet Reviewed"
        });
        // Handle the API response if needed
        if (response?.data?.status) {
          setCustomNotes(customNotesArray);
          console.log("Update successful");
          setIsLoading(false);
        } else {
          console.log("Update failed");
          setIsLoading(false);
        }
      }
    }));
    const handleCustomNoteChanges = async (field, value, prod) => {
      // Create a new array with the updated product data
      const updatedProdsArray = customNotesArray.map((item) => {
        if (item.id === prod.id) {
          return { ...item, [field]: value };
        }
        return item;
      });

      // Update the local state
      setCustomNotesArray(updatedProdsArray);
    };

    const customerColumns = [
      // { id: (_prod, idx) => idx + 1, label: "NO." },
      { id: "question", label: "Custom Notes", maxWidth: 320, minWidth: 200 },

      {
        id: (prod) => {
          let options = [
            "Yes. Reviewed. Good to go.",
            "Yes. Reviewed w/ Open Questions",
            "Yes. Not Yet Reviewed"
          ];
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4
              }}
            >
              <DropdownField
                question={"Status"}
                value={prod?.status || ""}
                options={options}
                handleChangeDropdown={(event) =>
                  handleCustomNoteChanges("status", event.target.value, prod)
                }
              />

              <NoteField
                question={"Notes"}
                value={prod?.note || ""}
                multiline={true}
                maxRows={5}
                minRows={1}
                handleNoteBlur={(event) =>
                  handleCustomNoteChanges("note", event.target.value, prod)
                }
                type={"text"}
              />
            </div>
          );
        },
        label: "Action",
        align: "center",
        maxWidth: 300
      }
    ];
    return (
      <Box sx={{ flexGrow: 1, p: 0.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {customNotesArray.length > 0 ? (
              <Paper
                sx={{
                  width: "100%",
                  overflow: "auto",
                  border: "1px solid #6685ac"
                }}
              >
                <TableContainer
                  style={{
                    maxHeight: "calc(100vh - 250px)",
                    overflowY: "auto"
                  }}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{ borderCollapse: "collapse" }}
                  >
                    <TableHead>
                      <TableRow>
                        {customerColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                              // borderColor: "#6685ac",
                              fontWeight: "bold",
                              border: "0.5px solid #6685ac"
                            }}
                            sx={{
                              border: "0.5px solid #6685ac",
                              backgroundColor: "#dae4f0"
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customNotesArray?.map((prod, idx) => {
                        console.log(prod, "prod3");
                        if (!prod.isActive) {
                          // Skip rendering if the product is not active
                          return null;
                        }
                        return (
                          <TableRow tabIndex={-1} key={prod.id || idx}>
                            {customerColumns.map((column) => {
                              const value = prod[column.id];
                              const columnId = column.id;
                              const valueToRender =
                                typeof columnId === "function"
                                  ? columnId(prod, idx)
                                  : value;

                              console.log(
                                "value-",
                                value,
                                columnId,
                                valueToRender
                              );

                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    maxWidth: column.maxWidth,
                                    borderColor: "#6685ac",
                                    border: "0.5px solid #6685ac"
                                  }}
                                  sx={{
                                    backgroundColor:
                                      column?.label !== "Action"
                                        ? "#eeeeee"
                                        : "white"
                                  }}
                                >
                                  {column.format &&
                                  typeof valueToRender === "number"
                                    ? column.format(valueToRender)
                                    : valueToRender}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            ) : (
              <div className="flex justify-center items-center text-lg h-full">
                No custom note to display
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default CustomNoteTable;
