import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { StepperContextProvide } from "../..";
import FurnitureTableRow from "./FurnitureTableRow";

const InScopeFurniture = ({ columns, selectedTab }) => {
  const { furnitureItems } = useContext(StepperContextProvide);

  const furnitureItemsToConsider = useMemo(() => {
    if (selectedTab === 0) {
      return furnitureItems;
    } else {
      return furnitureItems?.filter((furniture) => furniture?.in_scope);
    }
  }, [furnitureItems, selectedTab]);

  return (
    <>
      {" "}
      <Grid container spacing={2} sx={{ paddingTop: 1 }}>
        <Grid item xs={12} md={12}>
          <Paper
            sx={{
              width: "100%",
              overflow: "auto",
              border: "1px solid #6685ac"
            }}
          >
            <TableContainer
              style={{
                maxHeight: "calc(100vh - 200px)",
                overflowY: "auto"
              }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ borderCollapse: "collapse" }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.label}
                        align={column.align}
                        style={{
                          maxWidth: column.maxWidth,
                          minWidth: column.minWidth,
                          fontWeight: "bold"
                        }}
                        sx={{
                          border: "0.5px solid #6685ac",
                          backgroundColor: "#dae4f0"
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {furnitureItemsToConsider?.length > 0 ? (
                    furnitureItemsToConsider?.map((prod, idx) => (
                      <TableRow tabIndex={-1} key={prod.id || idx + prod.item}>
                        {columns.map((column) => {
                          return (
                            <FurnitureTableRow
                              prod={prod}
                              column={column}
                              idx={prod.originalIndex || idx}
                            />
                          );
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        sx={{
                          textAlign: "center",
                          verticalAlign: "middle",
                          height: "100px"
                        }}
                      >
                        No Furniture
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default InScopeFurniture;
