import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import momentTz from "moment-timezone";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationStatus from "../UI/ConfirmationStatus";
import DropdownField from "../question-fields/dropdown";
import CustomerName from "../UI/CustomerName";

const CustomerTable = ({
  customers,
  page,
  rowsPerPage,
  isLoading,
  handleChanges,
  handleCreatedAtSorting,
  curOrder,
}) => {
  const navigate = useNavigate();

  const handleEditClick = (customerId) => {
    navigate(`/ek1ThPbdwtcAQMtg71pMeCl1XRdrs85H?id=${customerId}`);
  };

  const columns = [
    { id: (_prod, idx) => idx + 1, label: "NO.", sticky: true, maxWidth: 5 },
    {
      id: (prod) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            <IconButton onClick={() => handleEditClick(prod.zc_po_id)}>
              <EditIcon />
            </IconButton>
          </div>
        );
      },
      label: "Edit",
      align: "center",
      maxWidth: 20,
    },
    {
      id: (c) => <CustomerName customer={c} />,
      label: "Customer Name",
      maxWidth: 80,
      minWidth: 30,
      // sticky: true,
    },
    {
      id: "property_type",
      label: "Property Type",
      maxWidth: 80,
      minWidth: 50,
    },

    {
      id: (prod) => (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            textAlign: "justify",
            gap: 4,
            flexDirection: "column",
            // fontSize: "14px",
          }}
        >
          {prod?.job_type?.map((job, index) => (
            <span key={index}>
              {job}
              {index < prod.job_type.length - 1 ? ", " : ""}
            </span>
          ))}
        </span>
      ),
      label: "Job Type",
      align: "center",
      // maxWidth: 300,
      minWidth: 40,
      maxWidth: 100,
    },
    {
      id: (prod) => {
        let options = ["Not Yet Started", "In Process", "Complete"];
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            <DropdownField
              question={"Project Status"}
              value={prod?.project_status || ""}
              options={options}
              handleChangeDropdown={(event) =>
                handleChanges(event.target.value, prod)
              }
            />
          </div>
        );
      },
      label: "Project Status",
      align: "center",
      maxWidth: 300,
    },

    {
      id: (prod) => (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 4,
          }}
        >
          {prod?.estimated_start_date
            ? moment(prod?.estimated_start_date).format("ll")
            : ""}
        </span>
      ),
      label: "Estimated Start Date",
      align: "center",
      maxWidth: 60,
      minWidth: 10,
    },
    {
      id: (prod) => <ConfirmationStatus confirmations={prod?.confirmations} />,
      label: "Status",
      align: "center",
      maxWidth: 340,
      minWidth: 80,
    },
    {
      id: (p) => {
        const tzDate = momentTz.tz(p.createdAt, "America/New_York");
        const formattedDate = tzDate.format("Do MMMM YYYY, h:mm:ss a");
        return formattedDate;
      },
      label: (
        <div>
          Created At{" "}
          <span onClick={handleCreatedAtSorting} style={{ cursor: "pointer" }}>
            {curOrder === "DESC" ? "↓" : "↑"}{" "}
          </span>
        </div>
      ),
      align: "center",
      maxWidth: 200,
      minWidth: 80,
    },
  ];

  const displayedCustomers = customers;

  return (
    <Paper sx={{ width: "100%", border: "1px solid #6685ac" }}>
      <TableContainer
        style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                    fontWeight: "bold",
                    position: column.sticky ? "sticky" : "static",
                    left: column.sticky ? `${index * 66}px` : "auto", // Adjust the `left` value based on the column index and width
                    zIndex: column.sticky ? 1 : "auto",
                    backgroundColor: "#dae4f0",
                  }}
                  sx={{
                    border: "0.5px solid #6685ac",
                    backgroundColor: "#dae4f0",
                    padding: "12px 20px",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && displayedCustomers?.length ? (
              displayedCustomers?.map((prod, idx) => (
                <TableRow tabIndex={-1} key={prod.id || idx}>
                  {columns.map((column, index) => {
                    const value = prod[column.id];
                    const columnId = column.id;
                    const valueToRender =
                      typeof columnId === "function"
                        ? columnId(prod, page * rowsPerPage + idx)
                        : value;

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          maxWidth: column.maxWidth,
                          minWidth: column.minWidth,
                          position: column.sticky ? "sticky" : "static",
                          left: column.sticky ? `${index * 66}px` : "auto", // Adjust the `left` value based on the column index and width
                          zIndex: column.sticky ? 1 : "auto",
                          backgroundColor: column.sticky
                            ? "#ffffff"
                            : "inherit",
                          borderColor: "#6685ac",
                          border: "0.5px solid #6685ac",
                          padding: "4px 14px",
                        }}
                      >
                        {column.format && typeof valueToRender === "number"
                          ? column.format(valueToRender)
                          : valueToRender}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}
                >
                  <Typography>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      "No Record found for your search"
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CustomerTable;
