import { QuestionFormField } from "../FormBuilder/Questions";

export const TABLE_KEY_MAPPING = {
  "Confirmation Email Review": ["email_confirmed"],
  "Delivery Ticket?": ["delivery_ticket_confirmed"],
  "Supplier Receipt": ["supplier_receipt_confirmed"],
  "COI?": ["coi_confirmed"],
  "Project Data": ["project_data_confirmed"],
  "Transition Confirmation": ["transition_confirmed"],
  "SF CONFIRMATION": ["sf_confirmed"],
  Invoice: ["invoice_confirmed"],
  "Custome Notes": ["custom_notes_confirmed"],
  "Open Question": ["open_questions_confirmed"],
  "Project Status": ["project_status"],
};

export const getOptionsFromQue = (que) => {
  const questionsNestedArrayList = QuestionFormField.slice(
    1,
    QuestionFormField.length
  );

  if (que === "Project Data") {
    que = "MAGICPLAN";
  } else if (que === "Invoice" || que === "Custome Notes") {
    return [
      "Yes. Reviewed. Good to go.",
      "Yes. Reviewed w/ Open Questions",
      "Yes. Not Yet Reviewed",
    ];
  } else if (que === "Transition Confirmation") {
    return [
      "Invoice : Reviewed and Approved",
      "Invoice : Reviewed w/ Open Questions",
      "Ops : Reviewed and Approved",
      "Ops : Reviewed w/ Open Questions",
    ];
  } else if (que === "Project Status") {
    return ["Not Yet Started", "In Process", "Complete"];
  } else if (que === "Open Question") {
    return ["DONE", "PENDING"];
  }

  const flattedQuestions = questionsNestedArrayList?.flatMap((q) =>
    q.flatMap((subArray) => subArray)
  );

  const question = flattedQuestions.find((q) => q.question === que);
  return question?.option;
};

const projectDataExcludeOptions = [
  "Yes. Reviewed w/ Open Questions",
  "Yes. Not Yet Reviewed",
];

export const isProjectDataStatusconfirmed = (confirmations) => {
  const isConfirmed = (value) =>
    value === "Yes. Reviewed. Good to go." ||
    value === "No existing file" ||
    value === null ||
    !projectDataExcludeOptions.includes(value);

  let project_data_confirmed = false;

  if (
    isConfirmed(confirmations.matterport_confirmed) &&
    isConfirmed(confirmations.magicplan_confirmed)
  ) {
    project_data_confirmed = true;
  }

  return project_data_confirmed;
};
