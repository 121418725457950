import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSearchParams } from "react-router-dom";
import { getOpsResultingData } from "../services/finance.service";
import { getAuth } from "../services/identity.service";
import moment from "moment";

const InitialInvoicePdf = () => {

    const [data, setData] = useState({});
    const [searchParams] = useSearchParams();
    // const auth = getAuth();

    const queryValue = searchParams.get("id");

    useEffect(() => {
        // if (!!auth) {
        const fetchData = async () => {
            const getData = await getOpsResultingData(queryValue);
            setData(getData?.data?.entity?.details?.output?.data?.po_data);
        };
        fetchData();
        // }
    }, []);
    const QuestionAnswerPair = ({ question, answer, style, type = "TEXT" }) => {
        return (
            <Grid container className="parentGridStyle" sx={{ mb: 1 }}>
                <Grid item xs={6} className="quesGridStyle">
                    <Typography variant="body2" className="typoStyle">
                        {question}
                    </Typography>
                </Grid>
                <Grid item xs={0.01}>
                    <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5.95} className={`ansGridStyle`}>
                    <Typography variant="body2" className="ansTypoStyle" sx={style}>
                        {type === "DATE" ? moment(answer).format('ll') : type == "HTML" ? <span dangerouslySetInnerHTML={{ __html: answer }} /> : answer}
                    </Typography>
                </Grid>
            </Grid>
        );
    };




    console.log("data1", data)



    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

            <Card sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: '90vw', m: 2 }}>
                <CardContent sx={{ height: "100%", width: "100%" }}>
                    <Box sx={{ flexGrow: 1, height: "100%", width: "100%" }}>
                        <Grid container spacing={2} sx={{ height: "100%", width: "100%" }}>

                            <Grid xs={12} md={12}>
                                <Box sx={{ p: 1 }}>

                                    <Box >
                                        <Box sx={{ pt: 1 }}>
                                            <QuestionAnswerPair question="Customer Name" answer={data?.Deal_Name || ''} />

                                            <QuestionAnswerPair question="Status" answer={data?.Stage || ''} />


                                            <QuestionAnswerPair question="Owner" answer={data?.Owner?.name || ''} />


                                            <QuestionAnswerPair question="Job Type" answer={data?.Job_Type || ''} />

                                            <QuestionAnswerPair question="Property Type" answer={data?.Property_Type || ''} />
                                            {
                                                data?.Doorman_building === "Yes" ?

                                                    <QuestionAnswerPair question="Doorman_building ?" answer={data?.Doorman_building || ''} /> : ""
                                            }
                                            {
                                                data?.Metal_doors_to_be_scribed_around ?

                                                    <QuestionAnswerPair question="Metal doors to be scribed around?" answer={"Yes" || ''} /> : ""
                                            }

                                            {
                                                data?.Special_Instructions_For_Getting_Into_Home_Notes !== "" && <>
                                                    <QuestionAnswerPair question="Special Instructions For Getting Into Home Notes" answer={data?.Special_Instructions_For_Getting_Into_Home_Notes || ''} />


                                                </>
                                            }

                                            {/* //Confirmation that FlooredAtHome will be the only trade New */}

                                            {
                                                data?.Confirmation_that_FAH_will_be_the_only_trade && data?.Confirmation_that_FAH_will_be_the_only_trade == "No" ?

                                                    <QuestionAnswerPair question="Confirmation that FlooredAtHome will be the only trade" answer={data?.Confirmation_that_FAH_will_be_the_only_trade || ''} /> : ""
                                            }

                                            {/* //Disposal Plan New */}
                                            {
                                                data?.Disposal_Plan ?

                                                    <QuestionAnswerPair question="Disposal Plan" answer={data?.Disposal_Plan || ''} /> : ""
                                            }

                                            {/* //Walls & Baseboard to be painted after the project New  */}
                                            {
                                                data?.Walls_Baseboard_to_be_painted_after_the_project && data?.Walls_Baseboard_to_be_painted_after_the_project == "No" ?

                                                    <QuestionAnswerPair question="Walls & Baseboard to be painted after the project" answer={data?.Walls_Baseboard_to_be_painted_after_the_project || ''} /> : ""
                                            }


                                            <QuestionAnswerPair question="City" answer={data?.City || ''} />

                                            <QuestionAnswerPair question="State" answer={data?.State || ''} />


                                            <QuestionAnswerPair question="Total Job Size" answer={data?.Amount || ''} />


                                            <QuestionAnswerPair question="Deposit Taken" answer={data?.Deposit_Taken || ''} />
                                            <QuestionAnswerPair question="Deposit Taken date" answer={data?.Deposit_Taken_date || ''} type="DATETIME" />

                                            <QuestionAnswerPair question="Method Of Deposit" answer={data?.Method_of_Deposit || ''} />

                                            <QuestionAnswerPair question="Payment Terms" answer={data?.Payment_Terms || ''} />


                                            <QuestionAnswerPair question="Other Payment Notes" answer={data?.Other_Payment_Notes || ''} />

                                            {
                                                data?.Delivery_Required === "Delivery Required" ?
                                                    <QuestionAnswerPair question="Delivery Required" answer={data?.Delivery_Required || ''} style={{ color: "red" }} /> :
                                                    <QuestionAnswerPair question="Delivery Required" answer={data?.Delivery_Required || ''} />


                                            }
                                            {
                                                data?.Material_Confirmed === "Material Required: Not Yet Confirmed" ?
                                                    <QuestionAnswerPair question="Material Confirmed" answer={data?.Material_Confirmed || ''} style={{ color: "red" }} /> :
                                                    <QuestionAnswerPair question="Material Confirmed" answer={data?.Material_Confirmed || ''} />


                                            }
                                            <QuestionAnswerPair question="Requires COI" answer={data?.Requires_COI || ''} />
                                            <QuestionAnswerPair question="Estimated or Agreed Start Date" answer={data?.Estimated_or_Agreed_Start_Date || ''} type="DATE" />


                                            {
                                                data?.Confirmed_timing_details && (data?.Confirmed_timing_details === "Timing Confirmed with Ops" || data?.Confirmed_timing_details === "Start Date 100% Confirmed with OPS" || data?.Confirmed_timing_details === "Start Date 100% Confirmed with Sales") ?
                                                    <QuestionAnswerPair question=" Confirmed timing details" answer={data?.Confirmed_timing_details || ''} style={{ color: "red" }} /> : <QuestionAnswerPair question="Confirmed timing details" answer={data?.Confirmed_timing_details || ''} />
                                            }
                                            <QuestionAnswerPair question="Timing Requirements" answer={data?.Timing_Requirements || ''} />
                                            <QuestionAnswerPair question="Customer Plan During Project" answer={data?.Customer_Plan_During_Project || ''} />
                                            {/* //Customer Plan For The Project Notes New */}
                                            {
                                                data?.Customer_Plan_For_The_Project_Notes ?

                                                    <QuestionAnswerPair question="Customer Plan For The Project Notes" answer={data?.Customer_Plan_For_The_Project_Notes || ''} /> : ""
                                            }
                                            {
                                                data?.Critical_Timing_Requirements && data?.Critical_Timing_Requirements === "None" ?
                                                    <QuestionAnswerPair question="Critical Timing Requirements" answer={data?.Critical_Timing_Requirements || ''} /> :
                                                    <QuestionAnswerPair question="Critical Timing Requirements" answer={data?.Critical_Timing_Requirements || ''} style={{ color: "red" }} />
                                            }

                                            <QuestionAnswerPair question="When does the floor need to be used" answer={data?.When_does_the_floor_need_to_be_used || ''} type="DATE" />

                                            <QuestionAnswerPair question="Earliest date customer can start" answer={data?.Earliest_date_customer_can_start || ''} type="DATE" />
                                            <QuestionAnswerPair question="Other Project Timing Notes" answer={data?.Other_Project_Timing_Notes || ''} type="HTML" />

                                            {
                                                data?.Any_project_complications_to_be_discussed_with_OPS && data?.Any_project_complications_to_be_discussed_with_OPS === "Yes" ? <QuestionAnswerPair question="Any project complications to be discussed with OPS directly" answer={data?.Any_project_complications_to_be_discussed_with_OPS || ''} style={{ color: "red" }} />
                                                    :
                                                    <QuestionAnswerPair question="Any project complications to be discussed with OPS directly" answer={data?.Any_project_complications_to_be_discussed_with_OPS || ''} />
                                            }

                                            {
                                                data?.Complications_to_be_discussed && <QuestionAnswerPair question="Complications to be discussed" answer={data?.Complications_to_be_discussed || ''} />
                                            }

                                            {
                                                data?.Are_we_matching_any_existing_floor_Refi_orInst && data?.Are_we_matching_any_existing_floor_Refi_orInst === "Yes" ? <QuestionAnswerPair question="Are we matching any existing floor (Refinishing or Install)" answer={data?.Are_we_matching_any_existing_floor_Refi_orInst || ''} style={{ color: "red" }} />
                                                    :
                                                    <QuestionAnswerPair question="Are we matching any existing floor (Refinishing or Install)" answer={data?.Are_we_matching_any_existing_floor_Refi_orInst || ''} />
                                            }
                                            {/* //Is any leveling needed New */}
                                            {
                                                data?.Is_any_leveling_needed && data?.Is_any_leveling_needed == "Yes" ? <QuestionAnswerPair question="Is any leveling needed" answer={"Yes" || ''} /> : ""
                                            }
                                            {/* //Why Customer is Doing the Project New */}
                                            {
                                                data?.Why_Customer_is_Doing_the_Project ? <QuestionAnswerPair question="Why Customer is Doing the Project" answer={data?.Why_Customer_is_Doing_the_Project || ''} /> : ""
                                            }
                                            {/* //Most Important thing to customer about project New*/}
                                            {
                                                data?.Most_Important_thing_to_customer_about_project ? <QuestionAnswerPair question="Most Important thing to customer about project" answer={data?.Most_Important_thing_to_customer_about_project || ''} /> : ""
                                            }


                                            {
                                                data?.Are_we_matching_any_existing_floor_Refi_orInst && data?.Are_we_matching_any_existing_floor_Refi_orInst === "No" ? <QuestionAnswerPair question="Confirmation for OPS to reach out to the customer" answer={data?.Confirmation_for_OPS_to_reach_out_to_the_customer || ''} style={{ color: "red" }} />
                                                    :
                                                    <QuestionAnswerPair question="Confirmation for OPS to reach out to the customer" answer={data?.Confirmation_for_OPS_to_reach_out_to_the_customer || ''} />
                                            }

                                            {
                                                data?.Is_there_any_furniture_to_be_moved_between_floors && data?.Is_there_any_furniture_to_be_moved_between_floors === "Yes" ? <QuestionAnswerPair question="Is there any furniture to be moved between floors" answer={data?.Is_there_any_furniture_to_be_moved_between_floors || ''} style={{ color: "red" }} />
                                                    :
                                                    <QuestionAnswerPair question="Is there any furniture to be moved between floors" answer={data?.Is_there_any_furniture_to_be_moved_between_floors || ''} />
                                            }

                                            {
                                                data?.Any_pieces_that_will_require_more_than_2_people && data?.Any_pieces_that_will_require_more_than_2_people === "Yes" ? <QuestionAnswerPair question="Any pieces that will require more than 2 people" answer={data?.Any_pieces_that_will_require_more_than_2_people || ''} style={{ color: "red" }} />
                                                    :
                                                    <QuestionAnswerPair question="Any pieces that will require more than 2 people" answer={data?.Any_pieces_that_will_require_more_than_2_people || ''} />
                                            }
                                            {/* //Other Notes New */}
                                            <QuestionAnswerPair question="Other Notes" answer={data?.Other_Notes || ''} />

                                            {/* //Will the new floor be lower than the current floor New */}
                                            {
                                                data?.Will_the_new_floor_be_lower_than_the_current_floor && data?.Will_the_new_floor_be_lower_than_the_current_floor == "Yes" ?

                                                    <QuestionAnswerPair question="Will the new floor be lower than the current floor" answer={data?.Will_the_new_floor_be_lower_than_the_current_floor || ''} />
                                                    : ""
                                            }

                                            {/* //Doors jams expected to be cut? New */}
                                            {
                                                data?.Doors_jams_expected_to_be_cut ?

                                                    <QuestionAnswerPair question="Doors jams expected to be cut?" answer={data?.Doors_jams_expected_to_be_cut || ''} />
                                                    : ""
                                            }
                                            {/* //Doors expected to be cut? New */}
                                            {
                                                data?.Doors_expected_to_be_cut ?

                                                    <QuestionAnswerPair question="Doors expected to be cut?" answer={"Yes" || ''} />
                                                    : ""
                                            }
                                            {/* //Is Appliances In Scope & Appliances has value New */}
                                            {
                                                data?.Is_Appliances_In_Scope && data?.Is_Appliances_In_Scope == "Yes" && data?.Appliances?.length > 0 ?

                                                    <QuestionAnswerPair question="Appliances" answer={data?.Appliances || ''} />
                                                    : ""
                                            }
                                            {/* //Is Special Items In Scope & Special Items has value New */}
                                            {
                                                data?.Is_Special_Items_In_Scope && data?.Is_Special_Items_In_Scope == "Yes" && data?.Special_Items?.length > 0 ?

                                                    <QuestionAnswerPair question="Special Items" answer={data?.Special_Items || ''} />
                                                    : ""
                                            }

                                        </Box>
                                    </Box>

                                </Box>
                            </Grid>



                        </Grid>
                    </Box>
                </CardContent>
            </Card >
        </div>
    );
};


export default InitialInvoicePdf;
