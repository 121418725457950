import { Grid } from "@mui/material";
import React from "react";

const DeliveryTicket = ({
  fieldsToRender,
  dropdownValue,
  QuestionFormField,
  activeStep,
  questData,
}) => {
  const DeliveryQues = QuestionFormField?.[0];
  const SupplierQues = QuestionFormField?.[1];
  console.log("DeliveryQues", DeliveryQues);
  const currentQuestData =
    questData.find((q) => q.question === DeliveryQues[0]?.question) || {};
  const valueKey = DeliveryQues[0]?.key;
  const value = currentQuestData[valueKey] || "";
  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {DeliveryQues?.map((field, idx) => {
            const qKey = DeliveryQues[0]?.question + field.key + idx;
            return (
              <Grid item xs={field.grid || 12} key={qKey}>
                {fieldsToRender(field, DeliveryQues[0]?.question)}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {value !== "Not Needed" && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {SupplierQues?.map((field, idx) => {
              const qKey = SupplierQues[0]?.question + field.key + idx;
              return (
                <Grid item xs={field.grid || 12} key={qKey}>
                  {fieldsToRender(field, SupplierQues[0]?.question)}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DeliveryTicket;
