import styled from "@emotion/styled";
import React, { useEffect, useMemo, useState } from "react";
import { TextField } from "@mui/material";
import { VerticalAlignBottom } from "@mui/icons-material";

const NoteTextField = styled(TextField)(({}) => ({
  "&.MuiTextField-root": {
    "& .MuiInputBase-input": {
      padding: "14px",
      fontSize: "13px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .css-lccy1c-MuiGrid-root": {
      paddingRight: "0",
    },
    "& .MuiInputLabel-root": {
      color: "#202020",
      fontSize: "13px",
      top: "2px",
      marginTop: "3px",
    },
    "& .MuiInputLabel-shrink": {
      marginTop: "0",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset>legend": {
      fontSize: "10px",
    },
  },
}));

const SeatchNoteField = ({
  question,
  value,
  handleNoteBlur,
  multiline = false,
  maxRows,
  minRows,
  name,
  id,
  latestValue,
  type = "text",
  event = "onBlur",
}) => {
  const [focused, setFocused] = useState(false);
  const [newVal, setNewVal] = useState("");

  const formattedValue = useMemo(() => {
    return multiline ? value?.replace(/<br\s*\/?>/gi, "\n") : value;
  }, [multiline, value]);

  const handleFocus = (e) => {
    setFocused(true);
  };

  const handleBlur = async () => {
    handleNoteBlur(newVal);
    setFocused(false);
  };

  useEffect(() => {
    if (!value) setNewVal("");
  }, [value]);

  useEffect(() => {
    setFocused(false);
  }, [id]);

  const handleChange = (e) => {
    setNewVal(e.target.value);
  };

  return (
    <>
      <NoteTextField
        name={name}
        id={id}
        type={type}
        variant="outlined"
        fullWidth
        size="small"
        onFocus={handleFocus}
        label={question}
        value={newVal || ""}
        InputLabelProps={{ shrink: focused || Boolean(latestValue || value) }}
        onBlur={handleBlur}
        onChange={handleChange}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
      />
    </>
  );
};

export default SeatchNoteField;
