import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useContext, useMemo, useState } from "react";
import { StepperContextProvide } from "..";
import ImageUploadField from "../question-fields/ImageUploadField";
import {
  deleteFurniture,
  updateTransition,
  uploadImage,
} from "../services/finance.service";
import DropdownField from "../question-fields/dropdown";
import NoteField from "../question-fields/note";
import { Delete } from "@mui/icons-material";
import InScopeFurniture from "../Forms/FurnitureAudit/InScopeFurniture";
import _ from "lodash";

const FurnitureDialog = ({
  openEditDialog,
  setOpenEditDialog,
  setIsLoading,
  customerID,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const { furnitureItems, setFurnitureItems, fvFloors, fvRooms, zc_po_id } =
    useContext(StepperContextProvide);

  const floorOptions = useMemo(() => {
    return fvFloors.map((floor) => floor.Name);
  }, [fvFloors]);

  const handleAddQuestion = () => {
    const singleQuestion = {
      item: "",
      note: "",
      images: [],
      zc_po_id: zc_po_id,
      qty: "",
      size: "",
      in_scope: false,
      roomId: "",
      floorId: "",
    };
    setFurnitureItems((prev) => {
      return [...prev, { ...singleQuestion, originalIndex: prev.length }];
    });
    // setFurnitureItems([...furnitureItems, singleQuestion]);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setOpenDialog(true);
  };

  const handleChanges = async (field, value, prod, index) => {
    setFurnitureItems((prev) => {
      return prev.map((item, idx) => {
        const conditionToCheck = _.has(prod, "id")
          ? prod.id === item.id
          : idx === index;

        if (conditionToCheck) {
          return { ...item, [field]: value };
        }
        return item;
      });
    });
  };

  const handleImageChange = async (selectedFiles, index) => {
    setIsLoading(true);

    let formData = new FormData();
    formData.append("file", selectedFiles[0]);
    formData.append("originalname", selectedFiles[0]?.name);

    let imageUploaded = await uploadImage(formData);
    if (imageUploaded.data.status) {
      handleChanges(
        "images",
        [...furnitureItems[index]?.images, imageUploaded?.data?.entity],
        null,
        index
      );
      setIsLoading(false);
    }
  };

  const handleRemoveFiles = async (file, index) => {
    setIsLoading(true);
    let furnitures = furnitureItems;
    if (furnitureItems?.[index]?.id) {
      debugger;
      let filteredImages = furnitures[index]?.images.filter(
        (files, idx) => files !== file
      );

      furnitures[index].images = filteredImages;

      const allReviewed = furnitures.every(
        (prod) => prod.status === "Reviewed and Approved"
      );
      const response = await updateTransition(customerID, {
        transitionQuestions: furnitures,
        confirmStatus: allReviewed,
      });
      if (response?.data?.status) {
        setFurnitureItems(response?.data?.entity);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      let filteredImages = furnitures?.images?.filter(
        (files, idx) => files !== file
      );
      furnitures.images = filteredImages;
      setFurnitureItems(furnitures);
      setIsLoading(false);
    }
  };

  const columns = [
    { id: (_prod, idx) => idx + 1, label: "NO.", maxWidth: 1 },
    {
      id: (prod, index) => {
        let floorName = fvFloors?.find((floor) => floor?.id == prod?.floorId);

        let roomOption = [];
        if (prod?.floorId) {
          roomOption = fvRooms
            ?.filter((room) => room?.Field_Visit_Floor?.id == floorName?.id)
            ?.map((rm) => rm?.Display_Name);
        } else {
          // handleChanges("roomId", "", prod, index);
          roomOption = [];
        }
        let roomName = fvRooms?.find((room) => room?.id == prod?.roomId);

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px", // Adjust gap between dropdowns as needed
            }}
          >
            {/* Dropdown for selecting Floor */}
            <DropdownField
              question={""}
              value={floorName?.Name || ""}
              options={floorOptions}
              handleChangeDropdown={(event) => {
                let floor = fvFloors?.find(
                  (rm) => rm?.Name === event.target.value
                );
                handleChanges("floorId", floor?.id, prod, index);
              }}
            />

            {/* Dropdown for selecting Room */}
            <DropdownField
              question={""}
              value={roomName?.Display_Name || ""}
              options={roomOption}
              handleChangeDropdown={(event) => {
                let roomId = fvRooms?.find(
                  (rm) => rm?.Display_Name === event.target.value
                );

                handleChanges("roomId", roomId?.id, prod, index);
              }}
            />
          </div>
        );
      },
      label: "Place",
      align: "center",
      maxWidth: 40,
    },
    {
      id: (prod, index) => {
        let options = [
          "Side Table",
          "Ottoman",
          "Dining Chairs",
          "Buffer Table",
          "Chairs/Stools",
          "Nightstands",
          "Desk Chair",
          "Coffee Table",
          "Bookshelf",
          "Ent. Center(A/D)",
          "TV Stand",
          "Dining Table",
          "Kitchen Table",
          "Desk",
          "Sofa",
          "Sofa W/Pullout",
          "Bed(A/D)",
          "Bunk Bed(A/D)",
          "Dresser",
          "Other",
        ];
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            <DropdownField
              question={""}
              value={prod?.item || ""}
              options={options}
              handleChangeDropdown={(event) =>
                handleChanges("item", event.target.value, prod, index)
              }
            />
          </div>
        );
      },
      label: "Item",
      align: "center",
      maxWidth: 40,
    },
    {
      id: (prod, index) => {
        let options = ["Small", "Medium", "Large"];
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            <DropdownField
              question={""}
              value={prod?.size || ""}
              options={options}
              handleChangeDropdown={(event) =>
                handleChanges("size", event.target.value, prod, index)
              }
            />
          </div>
        );
      },
      label: "Size",
      align: "center",
      maxWidth: 20,
    },
    {
      id: (prod, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            <NoteField
              question={""}
              value={prod?.qty || ""}
              handleNoteBlur={(event) =>
                handleChanges("qty", event.target.value, prod, index)
              }
              type={"text"}
            />
          </div>
        );
      },
      label: "Quantity",
      align: "center",
      maxWidth: 20,
    },
    {
      id: (prod, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            <NoteField
              question={""}
              value={prod?.note || ""}
              multiline={true}
              maxRows={5}
              minRows={1}
              handleNoteBlur={(event) =>
                handleChanges("note", event.target.value, prod, index)
              }
              type={"text"}
            />
          </div>
        );
      },
      label: "Notes",
      align: "center",
      maxWidth: 60,
    },
    {
      id: (prod, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} key={prod?.question + index}>
                <ImageUploadField
                  handleImageChange={(selectedFiles) =>
                    handleImageChange(selectedFiles, index)
                  }
                  width="calc(100% - 158px)"
                  name="imageUpload"
                  id="imageUpload"
                  files={prod.images || []}
                  accept="image/*"
                  type={"IMAGE"}
                  imgUploading={false}
                  handleRemoveFiles={(file) => handleRemoveFiles(file, index)}
                />
              </Grid>
            </Grid>
          </div>
        );
      },
      label: "Images",
      align: "center",
      minWidth: 70,
    },
    {
      id: (prod, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 4,
          }}
        >
          <Checkbox
            checked={prod.in_scope || false}
            onChange={(e) =>
              handleChanges("in_scope", e.target.checked, prod, index)
            }
          />
        </div>
      ),
      label: "In Scope",
      align: "center",
      maxWidth: 10,
    },
    {
      id: (prod, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => handleDelete(index)}>
              <Delete />
            </IconButton>
          </div>
        );
      },
      label: "Delete",
      align: "center",
      maxWidth: 5,
    },
  ];

  const confirmDelete = async () => {
    if (furnitureItems?.[deleteIndex]?.id) {
      let response = await deleteFurniture(furnitureItems?.[deleteIndex]?.id);
      if (response?.data?.status) {
        const updatedProdsArray = furnitureItems.filter(
          (_, idx) => idx !== deleteIndex
        );

        setFurnitureItems(updatedProdsArray);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      const updatedProdsArray = furnitureItems.filter(
        (_, idx) => idx !== deleteIndex
      );

      setFurnitureItems(updatedProdsArray);
      setIsLoading(false);
    }

    setOpenDialog(false);
    setDeleteIndex(null);
  };

  return (
    <>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            padding: "10px",
            width: "95vw",
            height: "90vh",
            maxWidth: "95vw", // Ensures the dialog doesn't exceed 90vw
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddQuestion}
            startIcon={<AddIcon />}
          >
            Add Items
          </Button>
          {furnitureItems?.length > 0 && (
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{ ml: "auto" }}
            >
              <Tab label="All Furnitures" />
              <Tab label="InScope Furnitures" />
            </Tabs>
          )}
        </Box>
        <InScopeFurniture selectedTab={selectedTab} columns={columns} />
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Furniture?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FurnitureDialog;
