import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { getAuth } from "../../services/identity.service";
import MuiLinearProgress from "../../UI/MuiLinearProgress";
import FurnitureForm from "./FurnitureForm";
import FurnitureTable from "./FurnitureTable";
import {
  getCustomerFurnitureById,
  getOpsResultingData
} from "../../services/finance.service";
import { Backdrop, CircularProgress } from "@mui/material";

const MainFurniturePage = () => {
  const [furnitureData, setFurnitureData] = useState([]);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [fvRooms, setFvRooms] = useState([]);
  const [fvFloors, setFloors] = useState([]);

  const queryValue = searchParams.get("id");
  const fetchCustomerFurnitureData = async () => {
    const getData = await getCustomerFurnitureById(queryValue);
    if (getData?.status) {
      setFurnitureData(getData?.data?.entity);
    }
    setIsLoading(false);
  };

  const fetchOpsResultingData = async () => {
    const getData = await getOpsResultingData(queryValue);
    const opsData = getData?.data?.entity?.details?.output;
    setFvRooms(opsData?.data?.FV_Rooms);
    setFloors(opsData?.data?.FV_Floors);
    setInitialLoading(false);
  };

  useEffect(() => {
    setIsLoading(false);

    fetchOpsResultingData();
    fetchCustomerFurnitureData();
  }, []);

  return (
    <>
      {initialLoading ? (
        <MuiLinearProgress />
      ) : (
        <div className="App">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="primary" />
          </Backdrop>
          <FurnitureTable
            furnitureData={furnitureData}
            zc_po_id={queryValue}
            fetchCustomerData={fetchCustomerFurnitureData}
            fvFloors={fvFloors}
            fvRooms={fvRooms}
            setIsLoading={setIsLoading}
            setFurnitureData={setFurnitureData}
          />
        </div>
      )}
    </>
  );
};

export default MainFurniturePage;
