import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Tabs,
  Tab
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DropdownField from "../../question-fields/dropdown";
import NoteField from "../../question-fields/note";
import {
  deleteFurniture,
  deleteTransition,
  updateCustomerFurniture,
  updateOpenQuestion,
  updateQuestion,
  updateTransition,
  uploadImage
} from "../../services/finance.service";
import { Delete } from "@mui/icons-material";
import ImageUploadField from "../../question-fields/ImageUploadField";
import { StepperContextProvide } from "../../index";
import InScopeFurniture from "./InScopeFurniture";
import { toast } from "react-toastify";
import FurnitureAccordian from "./FurnitureAccordian";
import FurnitureDialog from "../../Dialoges/FurnitureDialog";

const FurnitureAudit = forwardRef(
  (
    { customerID, setIsLoading, fieldsToRender, questData, setQuestData },
    ref
  ) => {
    const { furnitureItems, setFurnitureItems, zc_po_id, fvRooms, fvFloors } =
      useContext(StepperContextProvide);

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const handleEditClick = () => {
      setOpenEditDialog(true);
    };

    let fields = [
      {
        question: "Is there furniture in the space?",
        key: "status",
        type: "DROPDOWN",
        option: ["Yes", "No", "Not Yet Reviewed"]
      },
      {
        question: "Who is Moving Furniture?",
        key: "details.status",
        type: "DROPDOWN",
        option: ["Customer Moving", "FlooredAtHome Moving"]
      },

      {
        question: "Items Reviewed",
        key: "details.itemReviwed",
        type: "CHECKBOX"
      },
      {
        question: "Plan Reviewed",
        key: "details.PlanReviewed",
        type: "CHECKBOX"
      },
      {
        question: "Add Items",
        key: "details.items",
        type: "Array"
      },
      {
        question: "Notes",
        key: "note",
        type: "TEXT",
        isMultiLine: false
      }
    ];

    const currentQuestData =
      questData.find((q) => q.question === fields?.[0].question) || {};

    // Check if currentQuestData is an empty object
    const isEmptyObject = Object.keys(currentQuestData).length === 0;

    // Use isEmptyObject to determine if the object is empty
    const valueKey = fields?.[0]?.key;

    const value = isEmptyObject ? "" : currentQuestData[valueKey] || "";
    const valueForNote = isEmptyObject ? "" : currentQuestData["note"] || "";
    const value2 = isEmptyObject
      ? ""
      : currentQuestData?.details?.["status"] || "";
    const itemsValue = isEmptyObject
      ? []
      : currentQuestData?.details?.["items"] || [];

    useImperativeHandle(ref, () => ({
      async performAction() {
        setIsLoading(true);
        let body = {};
        const handleApiCall = async (findQues) => {
          if (!isEmptyObject) {
            console.log(currentQuestData, "questData?.[findeQuestionIndex]");
            body = {
              ...currentQuestData,
              customerId: customerID,
              question: fields?.[0].question,
              status: value,
              note: valueForNote || "",
              doc_urls: [],
              images: [],
              details: {
                status: value == "Yes" ? value2 : "",
                PlanReviewed:
                  value == "Yes" && value2 === "FlooredAtHome Moving"
                    ? currentQuestData?.details?.["PlanReviewed"] || false
                    : false,
                itemReviwed:
                  value == "Yes" && value2 === "FlooredAtHome Moving"
                    ? currentQuestData?.details?.["itemReviwed"] || false
                    : false,
                items: []
              }
            };

            const response = await updateQuestion(customerID, body);

            if (response?.data?.status) {
              // Update questData with the new id if it's missing
              const updatedQuestData = questData.map((item, index) =>
                item?.question === findQues ? { ...response.data.entity } : item
              );

              setQuestData(updatedQuestData);
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        };
        await handleApiCall(fields?.[0]?.question);

        if (furnitureItems?.length > 0) {
          const responseFurniture = await updateCustomerFurniture(zc_po_id, {
            furnitures: furnitureItems
          });
          if (responseFurniture?.status) {
            setFurnitureItems(responseFurniture?.data?.entity);
          } else {
            toast.error(responseFurniture?.message || "Something went wrong");
          }
        }

        let status = "Yes. Not Yet Started";
        if (
          (body?.status === "Yes" &&
            body?.details?.status === "Customer Moving") ||
          body?.status === "No"
        ) {
          status = "Yes. Complete";
        } else if (body?.status === "Not Yet Reviewed") {
          status = "Yes. In Process";
        } else if (
          body?.status === "Yes" &&
          body?.details?.PlanReviewed &&
          body?.details?.itemReviwed
        ) {
          status = "Yes. Complete";
        }

        let response = await updateQuestion(customerID, {
          question: "FURNITURE_OVERALL_STATUS",
          status
        });

        console.log("statusResponse-", response);
      }
    }));

    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };

    return (
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} sx={{ paddingTop: 1 }}>
          <Grid item xs={12} key={"Open Question"}>
            {fieldsToRender(fields[0], fields?.[0].question)}
          </Grid>
        </Grid>

        {value === "Yes" && (
          <Grid container spacing={2} sx={{ paddingTop: 1 }}>
            <Grid item xs={12} key={fields[1]?.question}>
              {fieldsToRender(fields[1], fields?.[0].question)}
            </Grid>
          </Grid>
        )}
        {value === "Yes" && value2 && (
          <Grid container spacing={2} sx={{ paddingTop: 1 }}>
            <Grid item xs={12} key={fields[5]?.question}>
              {fieldsToRender(fields[5], fields?.[0].question)}
            </Grid>
          </Grid>
        )}

        {value === "Yes" && value2 === "FlooredAtHome Moving" && (
          <Grid container spacing={2} sx={{ paddingTop: 1 }}>
            <Grid item xs={2} key={"Open Question"}>
              {fieldsToRender(fields[2], fields?.[0].question)}
            </Grid>
            <Grid item xs={2} key={"Open Question"}>
              {fieldsToRender(fields[3], fields?.[0].question)}
            </Grid>
          </Grid>
        )}

        {value === "Yes" && value2 === "FlooredAtHome Moving" && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 2
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditClick}
                startIcon={<AddIcon />}
                sx={{ fontSize: "12px" }}
              >
                Add or Edit Items
              </Button>
              {furnitureItems?.length > 0 && (
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  sx={{ ml: "auto" }}
                >
                  <Tab label="All Furnitures" />
                  <Tab label="InScope Furnitures" />
                </Tabs>
              )}
            </Box>
            <FurnitureAccordian selectedTab={selectedTab} />
          </>
        )}
        {openEditDialog && (
          <FurnitureDialog
            openEditDialog={openEditDialog}
            setOpenEditDialog={setOpenEditDialog}
            setIsLoading={setIsLoading}
            customerID={customerID}
          />
        )}
      </Box>
    );
  }
);

export default FurnitureAudit;
