const ApiUrl = process.env.REACT_APP_API_URL;
const projctBacklogApiUrl = process.env.REACT_APP_PROJECT_BACKLOG_API_ENDPOINT;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

export const GET_OPS_RESULTING_DATA_BY_ID = (id) =>
  UrlParamsReplace("/customer/:id/get-ops-checklist-data", { id });

export const ADD_OPS_RESULT_DATA_TO_ZOHO = (id) =>
  UrlParamsReplace("/save-ops-data-to-zoho/:id", { id });
export const GET_CUSTOMER = (id) => UrlParamsReplace("/customer/:id", { id });

export const UPDATE_QUESTION = (id) =>
  UrlParamsReplace("/question/:id", { id });

export const UPDATE_INVOICE = (customerId) =>
  UrlParamsReplace("/invoice/:customerId/bulk", { customerId });

export const UPDATE_PROJECT_STATUS = (customerId) =>
  UrlParamsReplace("/customer/:customerId/status", { customerId });

export const UPDATE_CUSTOM_NOTE = (customerId) =>
  UrlParamsReplace("/custom-note/:customerId/bulk", { customerId });

export const UPDATE_OPEN_QUESTION = (customerId) =>
  UrlParamsReplace("/open-question/:customerId/bulk", { customerId });

export const UPDATE_TRANSITION = (customerId) =>
  UrlParamsReplace("/transition/:customerId/bulk", { customerId });

/** Project backlog status update API endpoint */
export const UPDATE_PROJECT_BACKLOG_STATUS = (zc_po_id) =>
  `${projctBacklogApiUrl}/customer/${zc_po_id}/update-project-backlog`;

export const GET_CUSTOMERS_LIST = (
  page,
  size,
  filter1,
  filter2,
  searchQuery,
  order = "DESC",
  currentTab
) => {
  let urlString = UrlParamsReplace(
    `/customer?page=${page}&size=${size}&order=${order}`,
    {}
  );

  if (filter1?.[0] !== "project_status") {
    urlString += `&project_status=${currentTab}`;
  }

  if (filter1 && filter1?.length > 0 && filter2 !== null) {
    filter1.forEach((k) => {
      urlString += `&${k}=${filter2}`;
    });
  }

  if (searchQuery) {
    urlString += `&searchToken=${searchQuery}`;
  }
  return urlString;
};

export const DELETE_OPEN_QUESTION = (customerId, questionId) => {
  console.log("DELETE_OPEN_QUESTION called with:", customerId, questionId);
  return UrlParamsReplace(`/open-question/${customerId}/${questionId}`, {});
};

export const DELETE_TRANSITION = (customerId, questionId) =>
  UrlParamsReplace(`/transition/:customerId/:questionId  `, {
    customerId,
    questionId
  });

export const UPDATE_INVOICE_PRODUCTS = (zcPoId) =>
  UrlParamsReplace("/invoice/:zcPoId", { zcPoId });

export const UPDATE_CUSTOM_NOTES = (zcPoId) =>
  UrlParamsReplace("/custom-note/:zcPoId", { zcPoId });

export const GET_USER_BY_ID = (id) => {
  return `https://fvbackend.kasawalkthrough.com/api/auth/get-user/${id}`;
};

export const UPDATE_ROOM_NAME_IN_ZOHO = () => {
  return "https://proposal-backend.kasawalkthrough.com/api/update-room-in-zoho";
};

export const UPLOAD_IMAGE = (customerId) =>
  "https://fvbackend.kasawalkthrough.com/api/upload-file";

export const GET_CUSTOMER_FURNITURE = (zc_po_id) =>
  UrlParamsReplace("/furniture/:zc_po_id", { zc_po_id });

export const UPDATE_CUSTOMER_FURNITURE = (zc_po_id) =>
  UrlParamsReplace("/furniture/:zc_po_id", { zc_po_id });

export const DELETE_FURNITURE = (furnitureId) =>
  UrlParamsReplace(`/furniture/:furnitureId`, {
    furnitureId
  });
export const DELETE_TOLLGATE = (furnitureId) =>
  UrlParamsReplace(`/tollgate/:furnitureId`, {
    furnitureId
  });

export const UPDATE_TOLLGATE = (zc_po_id) =>
  UrlParamsReplace("/tollgate/:zc_po_id/bulk", { zc_po_id });
