const red = [
  "Yes w/ Open Questions",
  "Yes. Not Yet Started",
  "Yes. Reviewed w/ Open Questions",
  "Reviewed w/ Open Questions",
  "Invoice : Reviewed w/ Open Questions",
  "Ops : Reviewed w/ Open Questions",
  "PENDING"
];
const yellow = ["Yes. In Process", "Yes. Not Yet Reviewed", "Not Yet Reviewed"];
const green = [
  "Yes. Confirmed",
  "Yes. Complete",
  "Yes. Reviewed. Good to go.",
  "Reviewed and Approved",
  "Invoice : Reviewed and Approved",
  "Ops : Reviewed and Approved",
  "DONE"
];

// const blue = ["Not Needed", "No existing file"];
const gray = ["Not Needed", "No existing file"];

export const blueOP = "#0408f833";
export const redOP = "#ff004136";
export const greenOP = "#04f82938";
export const yellowOP = "#f8d40447";
export const grayOP = "#09090929";

export const blueWithOP = "#0408f8c4";
export const redWithOP = "#ff0041ed";
export const greenWithOP = "#04a81d";
export const yellowWithOP = "#debe06ed";
export const grayWithOP = "#09090973";
export const defaultGray = "#ccc";

export const getColor = (ops) => {
  if (red?.includes(ops)) {
    return redOP;
  } else if (yellow?.includes(ops)) {
    return yellowOP;
  } else if (green?.includes(ops)) {
    return greenOP;
  } else if (gray?.includes(ops)) {
    return defaultGray;
  } else {
    return "white";
  }
};
export const getColorForButton = (ops) => {
  if (red?.includes(ops)) {
    return redWithOP;
  } else if (yellow?.includes(ops)) {
    return yellowWithOP;
  } else if (green?.includes(ops)) {
    return greenWithOP;
  } else if (gray?.includes(ops)) {
    return defaultGray;
  } else {
    return redWithOP;
  }
};

export const greenColor = "#04f82938";
export const redColor = "#ff0041ed";

export const getReviewStatusColor = (array, status, type = false) => {
  const allReviewed = array.every((prod) => prod.status === status);
  return allReviewed
    ? type
      ? greenOP
      : greenWithOP
    : type
    ? redOP
    : redWithOP;
};

// Helper function to check if the status is considered "good"
export const isStatusGood = (status) =>
  !status ||
  status === "Yes. Reviewed. Good to go." ||
  status === "No existing file";

export const isStatusInProgress = (status) =>
  status === "Yes. Not Yet Reviewed";

// Main function to get the color for the project data button
export const getColorProjectDataBtn = (questionData, type = false) => {
  // Find indices for MAGICPLAN and MATTERPORT questions
  const isStatusInRed = (status) =>
    status === "Yes. Reviewed w/ Open Questions";
  const findQuestionIndex = (question) =>
    questionData?.findIndex((ques) => ques.question === question);

  const findMagicPlanQIndex = findQuestionIndex("MAGICPLAN");
  const findMatterPortIndex = findQuestionIndex("MATTERPORT");

  // Check if both questions are found
  if (findMagicPlanQIndex === -1 && findMatterPortIndex === -1) {
    return type ? redOP : redWithOP; // Return default color if any question is not found
  }

  // Retrieve the status values
  const magicplanStatus = questionData[findMagicPlanQIndex]?.status;
  const matterPortStatus = questionData[findMatterPortIndex]?.status;

  // Determine the boolean values for both statuses
  const magicPlanValue = isStatusGood(magicplanStatus);
  const matterPortValue = isStatusGood(matterPortStatus);

  const atleastOneInProgress =
    isStatusInProgress(magicplanStatus) || isStatusInProgress(matterPortStatus);
  const atleastOneInRed =
    isStatusInRed(magicplanStatus) || isStatusInRed(matterPortStatus);

  // Set color based on the boolean values
  return magicPlanValue && matterPortValue
    ? type
      ? greenOP
      : greenWithOP
    : atleastOneInRed
    ? type
      ? redOP
      : redWithOP
    : atleastOneInProgress
    ? type
      ? yellowOP
      : yellowWithOP
    : type
    ? redOP
    : redWithOP;
};
// Main function to get the color for the COI button
export const getColorCOIBtn = (questionData, type = false) => {
  const isStatusCOI = (status) =>
    !status || status === "Yes. Complete" || status === "Not Needed";

  const isStatusInProgressCOI = (status) => status === "Yes. In Process";
  const isStatusInRed = (status) => status === "Yes. Not Yet Started";
  // Find indices for MAGICPLAN and MATTERPORT questions
  const findQuestionIndex = (question) =>
    questionData?.findIndex((ques) => ques.question === question);

  const findCOIQIndex = findQuestionIndex("COI?");
  const findBuildingApprovalIndex = findQuestionIndex("Building Approval?");

  // Check if both questions are found
  if (findCOIQIndex === -1 && findBuildingApprovalIndex === -1) {
    return type ? redOP : redWithOP; // Return default color if any question is not found
  }

  // Retrieve the status values
  const coiStatus = questionData[findCOIQIndex]?.status;
  const buildingApprovalStatus =
    questionData[findBuildingApprovalIndex]?.status;

  // Determine the boolean values for both statuses
  const coiValue = isStatusCOI(coiStatus);
  const buildingApprovalValue = isStatusCOI(buildingApprovalStatus);

  const atleastOneInProgress =
    isStatusInProgressCOI(coiStatus) ||
    isStatusInProgressCOI(buildingApprovalStatus);
  const atleastOneInRed =
    isStatusInRed(coiStatus) || isStatusInRed(buildingApprovalStatus);

  // Set color based on the boolean values
  return coiValue && buildingApprovalValue
    ? type
      ? greenOP
      : greenWithOP
    : atleastOneInRed
    ? type
      ? redOP
      : redWithOP
    : atleastOneInProgress
    ? type
      ? yellowOP
      : yellowWithOP
    : type
    ? redOP
    : redWithOP;
};
export const getColorSFRoomBtn = (questionData, type = false) => {
  const isStatusCOI = (status) => !status || status === "Reviewed and Approved";

  const isStatusInProgressCOI = (status) => status === "Not Yet Reviewed";
  const isStatusInRed = (status) => status === "Reviewed w/ Open Questions";
  // Find indices for MAGICPLAN and MATTERPORT questions
  const findQuestionIndex = (question) =>
    questionData?.findIndex((ques) => ques.question === question);

  const findMagicPlanQIndex = findQuestionIndex("SF CONFIRMATION");
  const findMatterPortIndex = findQuestionIndex(
    "Confirmation of spaces included in project"
  );

  // Check if both questions are found
  if (findMagicPlanQIndex === -1 && findMatterPortIndex === -1) {
    return type ? redOP : redWithOP; // Return default color if any question is not found
  }

  // Retrieve the status values
  const magicplanStatus = questionData[findMagicPlanQIndex]?.status;
  const matterPortStatus = questionData[findMatterPortIndex]?.status;

  // Determine the boolean values for both statuses
  const magicPlanValue = isStatusCOI(magicplanStatus);
  const matterPortValue = isStatusCOI(matterPortStatus);

  const atleastOneInProgress =
    isStatusInProgressCOI(magicplanStatus) ||
    isStatusInProgressCOI(matterPortStatus);
  const atleastOneInRed =
    isStatusInRed(magicplanStatus) || isStatusInRed(matterPortStatus);

  // Set color based on the boolean values
  return magicPlanValue && matterPortValue
    ? type
      ? greenOP
      : greenWithOP
    : atleastOneInRed
    ? type
      ? redOP
      : redWithOP
    : atleastOneInProgress
    ? type
      ? yellowOP
      : yellowWithOP
    : type
    ? redOP
    : redWithOP;
};

export const getColorEstDateBtn = (questionData, type = false) => {
  // Find indices for MAGICPLAN and MATTERPORT questions
  const findQuestionIndex = (question) =>
    questionData?.findIndex((ques) => ques.question === question);
  const findEstDateQIndex = findQuestionIndex("Estimated Start Date");
  // Retrieve the status values
  const EstQValue = questionData[findEstDateQIndex]?.status;
  const EstCheckBoxValue =
    questionData[findEstDateQIndex]?.details?.estStartDateConfirm;

  // Set color based on the boolean values
  return EstCheckBoxValue && !EstQValue
    ? type
      ? redOP
      : redWithOP
    : EstCheckBoxValue && EstQValue
    ? type
      ? greenOP
      : greenWithOP
    : type
    ? redOP
    : redWithOP;
};

export const getColorDeliveryTicketBtn = (questionData, type = false) => {
  // Helper function to find the index of a question
  const findQuestionIndex = (question) =>
    questionData?.findIndex((ques) => ques.question === question);

  const findDeliveryTicketQIndex = findQuestionIndex("Delivery Ticket?");
  const findSupplierReceiptIndex = findQuestionIndex("Supplier Receipt");

  // Check if the Delivery Ticket question is found
  if (findDeliveryTicketQIndex === -1) {
    return defaultGray; // Return default color if Delivery Ticket question is not found
  }

  // Retrieve the status values
  const deliveryTicketStatus = questionData[findDeliveryTicketQIndex]?.status;
  const supplierTicketStatus =
    findSupplierReceiptIndex !== -1
      ? questionData[findSupplierReceiptIndex]?.status
      : null;

  // Determine the color based on the statuses
  if (deliveryTicketStatus === "Yes. Complete") {
    if (!supplierTicketStatus || supplierTicketStatus === "Not Needed") {
      return type ? greenOP : greenWithOP; // Green
    }
    if (supplierTicketStatus === "Yes. In Process") {
      return type ? yellowOP : yellowWithOP; // Yellow
    }
    if (supplierTicketStatus === "Yes. Not Yet Started") {
      return type ? redOP : redWithOP; // Red
    }
    if (supplierTicketStatus === "Yes. Complete") {
      return type ? greenOP : greenWithOP;
    }
  }

  if (deliveryTicketStatus === "Yes. In Process") {
    return type ? yellowOP : yellowWithOP; // Yellow
  }

  if (deliveryTicketStatus === "Yes. Not Yet Started") {
    return type ? redOP : redWithOP; // Red
  }

  if (deliveryTicketStatus === "Not Needed") {
    return type ? defaultGray : defaultGray; // gray
  }

  return defaultGray; // Default color if none of the conditions are met
};

export const getColorFurnitureAudit = (questionData, type) => {
  let color = type ? redOP : redWithOP; // Default color if no match found

  // Find the index of the current question
  const currentQuesIndex = questionData?.findIndex(
    (ques) => ques.question === "Is there furniture in the space?"
  );

  if (currentQuesIndex !== -1) {
    const question = questionData[currentQuesIndex];
    const value = question["status"];
    const status = question?.details?.status;
    const itemReviewed = question?.details?.itemReviwed;
    const planReviewed = question?.details?.PlanReviewed;

    // Determine color based on the value and status
    if (value === "No") {
      color = type ? greenOP : greenWithOP;
    } else if (value === "Not Yet Reviewed") {
      color = type ? yellowOP : yellowWithOP;
    } else if (value === "Yes" && status === "Customer Moving") {
      color = type ? greenOP : greenWithOP;
    } else if (value === "Yes" && status === "FlooredAtHome Moving") {
      if (itemReviewed && planReviewed) {
        color = type ? greenOP : greenWithOP;
      }
    }
  }

  return color;
};
