const inProgressStatuses = ["Yes. In Process", "Yes. Not Yet Started"];

export const getDeliveryTicketStatus = (questData) => {
  let deliveryStatusToConsider = null;
  const deliveryTickeLatestStatus = questData?.find(
    (q) => q.question === "Delivery Ticket?"
  )?.status;
  const supplierReceiptLatestStatus = questData?.find(
    (q) => q.question === "Supplier Receipt"
  )?.status;

  if (deliveryTickeLatestStatus === "Yes. Complete") {
    if (inProgressStatuses.includes(supplierReceiptLatestStatus)) {
      deliveryStatusToConsider = supplierReceiptLatestStatus;
    } else {
      deliveryStatusToConsider = deliveryTickeLatestStatus;
    }
  } else if (deliveryTickeLatestStatus === "Not Needed") {
    deliveryStatusToConsider = "Not Needed";
  } else if (inProgressStatuses.includes(deliveryTickeLatestStatus)) {
    deliveryStatusToConsider = deliveryTickeLatestStatus;
  }

  return deliveryStatusToConsider;
};
